// TODO CONSTANTS Модального окна по генерации фильтров

export default {
  // Следим за отображением модального окна
  SHOW_FILTER_MODAL_TOGGLE: 'SHOW_FILTER_MODAL_TOGGLE',

  // Инициализация карт
  SHOW_FILTER_MODAL_INIT_CARD: 'SHOW_FILTER_MODAL_INIT_CARD',
  // Перенос карт
  SHOW_FILTER_MODAL_CHANGE: 'SHOW_FILTER_MODAL_CHANGE',

  // Поиск по фильтрам
  SHOW_FILTER_MODAL_SEARCH: 'SHOW_FILTER_MODAL_SEARCH',
  SHOW_FILTER_MODAL_SEARCH_CHANGE: 'SHOW_FILTER_MODAL_SEARCH_CHANGE',

  // Сохранение данных для поиска
  SHOW_FILTER_MODAL_SAVE_OTHER: 'SHOW_FILTER_MODAL_SAVE_OTHER',
  SHOW_FILTER_MODAL_SAVE_OTHER_CHANGE: 'SHOW_FILTER_MODAL_SAVE_OTHER_CHANGE',

  // Сброс данных на дефолтные
  SHOW_FILTER_MODAL_DEFAULT: 'SHOW_FILTER_MODAL_DEFAULT'
}
