export default {
  FAVOURITES_OBJECT_TOGGLE: 'FAVOURITES_OBJECT_TOGGLE',
  FAVOURITES_OBJECT_RESETS_ALL: 'FAVOURITES_OBJECT_RESETS_ALL',

  FAVOURITES_OBJECT_TABLE_DATA: 'FAVOURITES_OBJECT_TABLE_DATA',
  FAVOURITES_OBJECT_TABLE_DATA_SUCCESS: 'FAVOURITES_OBJECT_TABLE_DATA_SUCCESS',
  FAVOURITES_OBJECT_TABLE_DATA_ERROR: 'FAVOURITES_OBJECT_TABLE_DATA_ERROR',

  FAVOURITES_OBJECT_TABLE_SAVE_SORT: 'FAVOURITES_OBJECT_TABLE_SAVE_SORT',
  FAVOURITES_OBJECT_TABLE_SAVE_MAP: 'FAVOURITES_OBJECT_TABLE_SAVE_MAP',

  FAVOURITES_OBJECT_TABLE_OPEN_USER: 'FAVOURITES_OBJECT_TABLE_OPEN_USER',
  FAVOURITES_OBJECT_TABLE_OPEN_USER_SUCCESS: 'FAVOURITES_OBJECT_TABLE_OPEN_USER_SUCCESS',
  FAVOURITES_OBJECT_TABLE_OPEN_USER_ERROR: 'FAVOURITES_OBJECT_TABLE_OPEN_USER_ERROR',

  FAVOURITES_OBJECT_TABLE_OPEN_USER_SHOW: 'FAVOURITES_OBJECT_TABLE_OPEN_USER_SHOW',

  FAVOURITES_OBJECT_CHANGE: 'FAVOURITES_OBJECT_CHANGE',
  FAVOURITES_OBJECT_CHANGE_ALL: 'FAVOURITES_OBJECT_CHANGE_ALL',
}
