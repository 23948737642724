import constants from "../../../constants";
import update from "immutability-helper";
import { combineReducers } from "redux";

// TODO REDUCERS Страницы экспорта

const defaultDataTableExports = {
  processSend: true
};

// Хранение данных для таблицы экспорта
const propertyExports = (state = defaultDataTableExports, action) => {
  switch (action.type) {
    case constants.EXPORTS_DATA_TABLE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.EXPORTS_DATA_TABLE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.EXPORTS_DATA_TABLE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.EXPORTS_DEFAULT:
      return defaultDataTableExports;
    default:
      return state;
  }
};

let defaultPropertyTableExports = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  data: []
};

// Хранение свойств таблицы
const propertyTableExports = (state = defaultPropertyTableExports, action) => {
  switch (action.type) {
    case constants.EXPORTS_CHANGE_PROPERTY_IN_TABLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.EXPORTS_DEFAULT:
      return defaultPropertyTableExports;
    default:
      return state;
  }
};

const defaultConfirmDeleteSource = {
  toggle: false,
  data: null
};

// Состояние модального окна "Удаление ресурса"
const confirmDeleteSource = (state = defaultConfirmDeleteSource, action) => {
  switch (action.type) {
    case constants.EXPORTS_DATA_DELETE_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultEditSource = {
  processSend: false
};

// Отслеживание состояния при редактировании, добавлении нового и удалении ресурса
const editSource = (state = defaultEditSource, action) => {
  switch (action.type) {
    case constants.EXPORTS_DATA_EDIT_SOURCE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.EXPORTS_DATA_EDIT_SOURCE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.EXPORTS_DATA_EDIT_SOURCE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

export default combineReducers({
  propertyExports,
  propertyTableExports,
  confirmDeleteSource,
  editSource,
});