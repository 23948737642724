import { combineReducers } from "redux";
import constants from "../../../../constants";
import update from "immutability-helper";

const tableShowUserInfo = (state = [], action) => {
  switch (action.type) {
    case constants.OBJECT_TABLE_OPEN_USER_SHOW:
      return update(state, {
        $push: action.payload
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return [];
    default:
      return state;
  }
};

const tableShowUserInfoStatus = (state = {}, action) => {
  switch (action.type) {
    case constants.OBJECT_TABLE_OPEN_USER:
      return state;
    case constants.OBJECT_TABLE_OPEN_USER_SUCCESS:
      return update(state, {
        $merge: action.payload
      });
    case constants.OBJECT_TABLE_OPEN_USER_ERROR:
      return state;
    case constants.SEARCH_OBJECT_DEFAULT:
      return {};
    default:
      return state;
  }
};

const defaultSearchObjectsInApplicationsSaveBookmark = {
  id_objects: [],
};

/* Хранение закладок для объекта из заявок */
const searchObjectsInApplicationsSaveBookmark = (state = defaultSearchObjectsInApplicationsSaveBookmark, action) => {
  switch (action.type) {
    case constants.OBJECT_TABLE_SAVE_OBJECT:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return defaultSearchObjectsInApplicationsSaveBookmark;
    default:
      return state;
  }
};

export default combineReducers({
  tableShowUserInfoStatus,
  tableShowUserInfo,
  searchObjectsInApplicationsSaveBookmark,
});
