import { combineReducers } from 'redux';
import constants from "../../../constants";
import filterApplication from "./filterApplication";
import tables from "./tables";
import update from "immutability-helper";

const searchApplicationsToggle = (state = false, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_TOGGLE:
      return update(state, {
        $set: action.payload
      });
    case constants.APPLICATIONS_DATA_RESETS:
      return false;
    default:
      return state;
  }
};

const defaultSavePageState = {
  isSave: false,
  activeFilterState: null,
  inActiveFilterState: null,
  columnState: null,
  showFilterState: null
};

// Сохранение параметров страницы
const savePageState = (state = defaultSavePageState, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_SAVE_PAGE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

export default combineReducers({
  searchApplicationsToggle,
  savePageState,
  filterApplication,
  tables
});
