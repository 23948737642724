import React, { Component } from "react";

export class ErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error) {
    // Здесь можно отправлять данные в сервис сбора ошибок
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <h2 className="errorBoundary">Произошла ошибка!!!</h2>
      );
    }

    return this.props.children;
  }
}
