import { combineReducers } from 'redux';
import constants from '../../../constants';
import update from "immutability-helper";

// TODO REDUCERS Страницы словаря

const defaultPropertyHandbook = {
  processSend: true
};

// Храним свойства для таблицы
const propertyHandbook = (state = defaultPropertyHandbook, action) => {
  switch (action.type) {
    case constants.HANDBOOK_DATA_TABLE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.HANDBOOK_DATA_TABLE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.HANDBOOK_DATA_TABLE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.HANDBOOK_DEFAULT_DATA:
      return defaultPropertyHandbook;
    default:
      return state;
  }
};

const defaultDataInSelects = {
  processSend: false
};

// Храним данные для селектов
const dataInSelects = (state = defaultDataInSelects, action) => {
  switch (action.type) {
    case constants.HANDBOOK_DATA_SELECTS:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.HANDBOOK_DATA_SELECTS_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.HANDBOOK_DATA_SELECTS_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

const defaultPropertyTableSaveSortHandbook = {
  sortItem: "",
  columnSort: "",
};

// Сохраняем сортировку таблицы
const propertyTableSaveSortHandbook = (state = defaultPropertyTableSaveSortHandbook, action) => {
  switch (action.type) {
    case constants.HANDBOOK_SAVE_SORT_PROPERTY_IN_TABLE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultPropertyTableHandbook = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  data: []
};

// Храним свойства таблицы
const propertyTableHandbook = (state = defaultPropertyTableHandbook, action) => {
  switch (action.type) {
    case constants.HANDBOOK_CHANGE_PROPERTY_IN_TABLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.HANDBOOK_DEFAULT_DATA:
      return defaultPropertyTableHandbook;
    default:
      return state;
  }
};

const defaultModalEditOrAddHandbook = {
  modal: false,
  data: null
};

// Сохраняем состояние модального окна
const modalEditOrAddHandbook = (state = defaultModalEditOrAddHandbook, action) => {
  switch (action.type) {
    case constants.HANDBOOK_MODAL_EDIT_OR_ADD_TOGGLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.HANDBOOK_MODAL_EDIT_OR_ADD_DEFAULT:
      return defaultModalEditOrAddHandbook;
    default:
      return state;
  }
};

const defaultModalEditOrAddHandbookPole = {
  field_id: null,
  active: null,
  code: "",
  name: "",
  show: null,
  type: null,
  source: null,
  type_object: null
};

// Наполняем поля
const modalEditOrAddHandbookPole = (state = defaultModalEditOrAddHandbookPole, action) => {
  switch (action.type) {
    case constants.HANDBOOK_MODAL_EDIT_OR_ADD_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.HANDBOOK_MODAL_EDIT_OR_ADD_DEFAULT:
      return defaultModalEditOrAddHandbookPole;
    default:
      return state;
  }
};

const defaultModalEditOrAddHandbookValidate = {

};

// Валидируем поля
const modalEditOrAddHandbookValidate = (state = defaultModalEditOrAddHandbookValidate, action) => {
  switch (action.type) {
    case constants.HANDBOOK_MODAL_EDIT_OR_ADD_VALIDATE:
      return update(state, {
        $merge: action.payload
      });
    case constants.HANDBOOK_MODAL_EDIT_OR_ADD_DEFAULT:
      return {};
    default:
      return state;
  }
};

const defaultModalEditOrAddHandbookSend = {
  processSend: false
};

// Храним статус отправки словаря
const modalEditOrAddHandbookSend = (state = defaultModalEditOrAddHandbookSend, action) => {
  switch (action.type) {
    case constants.HANDBOOK_EDIT_OR_ADD_DATA:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.HANDBOOK_EDIT_OR_ADD_DATA_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.HANDBOOK_EDIT_OR_ADD_DATA_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

const defaultHandbookConfirmDeleteModal = {
  modal: false,
  data: null
};

// Храним состояние модального окна на удаление
const handbookConfirmDeleteModal = (state = defaultHandbookConfirmDeleteModal, action) => {
  switch (action.type) {
    case constants.HANDBOOK_CONFIRM_DELETE_TOGGLE:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

const defaultDeleteHandbookModal = {
  processSend: false
};

// Храним статус удаления
const deleteHandbookModal = (state = defaultDeleteHandbookModal, action) => {
  switch (action.type) {
    case constants.HANDBOOK_DELETE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.HANDBOOK_DELETE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.HANDBOOK_DELETE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

export default combineReducers({
  propertyHandbook,
  dataInSelects,
  propertyTableSaveSortHandbook,
  propertyTableHandbook,
  modalEditOrAddHandbook,
  modalEditOrAddHandbookPole,
  modalEditOrAddHandbookValidate,
  modalEditOrAddHandbookSend,
  handbookConfirmDeleteModal,
  deleteHandbookModal
});
