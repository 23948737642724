import constants from '../../../constants';
import { combineReducers } from "redux";
import update from "immutability-helper";

// TODO REDUCERS Страница лимитов экспорта

const defaultLimitParams = {
  title: "",
  exportItem: null,
  offices: null
};

// Хранение данных о первоначальных лимитах
const limitParams = (state = defaultLimitParams, action) => {
  switch (action.type) {
    case constants.SOURCE_ITEM_LIMITS:
      return update(state, {
        title: { $set: action.payload.title },
        exportItem: { $set: action.payload.exportItem },
        offices: { $set: action.payload.offices }
      });
    case constants.SOURCE_ITEM_LIMITS_DEFAULT:
      return defaultLimitParams;
    default:
      return state;
  }
};

const defaultForm = {};

// Хранение полей
const changeForm = (state = defaultForm, action) => {
  switch (action.type) {
    case constants.SOURCE_FORM_LIMITS_INIT:
      return action.payload;
    case constants.SOURCE_FORM_LIMITS_CHANGE:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });
    case constants.SOURCE_ITEM_LIMITS_DEFAULT:
      return defaultForm;
    default:
      return state;
  }
};

const defaultSendLimits = {
  processSend: false
};

// Отправка новых лимитов
const sendLimits = (state = defaultSendLimits, action) => {
  switch (action.type) {
    case constants.SOURCE_UPDATE_LIMITS:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.SOURCE_UPDATE_LIMITS_SUCCESS:
      return update(state, {
        processSend: { $set: true },
        $merge: { ...action.payload }
      });
    case constants.SOURCE_UPDATE_LIMITS_ERROR:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.SOURCE_ITEM_LIMITS_DEFAULT:
      return defaultSendLimits;
    default:
      return state;
  }
};

export default combineReducers({
  limitParams,
  changeForm,
  sendLimits
});
