import { combineReducers } from "redux";
import constants from "../../constants";
import modalSuccess from "./modalSuccess";
import update from "immutability-helper";

const defaultPropertyWorkerAndOffice = {
  processSend: false
};

// Подгрузка списка сотрудников с их местоположением
const propertyWorkerAndOffice = (state = defaultPropertyWorkerAndOffice, action) => {
  switch (action.type) {
    case constants.STATUS_WORKER:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.STATUS_WORKER_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.STATUS_WORKER_ERROR:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    default:
      return state;
  }
}

const defaultGetInfoResponsible = {
  processSend: false
};

// Получение информации об отвественном
const getInfoResponsible = (state = defaultGetInfoResponsible, action) => {
  switch (action.type) {
    case constants.STATUS_RESPONSIBLE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.STATUS_RESPONSIBLE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.STATUS_RESPONSIBLE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
}

// Храним свойства полей
const getProperty = (state = {}, action) => {
  switch (action.type) {
    case constants.GET_PROPERTY:
      return state;
    case constants.GET_PROPERTY_SUCCESS:
      return update(state, {
        $merge: action.payload
      });
    case constants.GET_PROPERTY_ERROR:
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  propertyWorkerAndOffice,
  getInfoResponsible,
  getProperty,
  modalSuccess
});