export default {
  SHOW_OBJECT_LOAD: 'SHOW_OBJECT_LOAD',
  SHOW_OBJECT_LOAD_SUCCESS: 'SHOW_OBJECT_LOAD_SUCCESS',
  SHOW_OBJECT_LOAD_ERROR: 'SHOW_OBJECT_LOAD_ERROR',

  SHOW_OBJECT_PARSE: 'SHOW_OBJECT_PARSE',
  SHOW_OBJECT_RESETS_ALL: 'SHOW_OBJECT_RESETS_ALL',

  SHOW_OBJECT_PREVIEW_TOGGLE: 'SHOW_OBJECT_PREVIEW_TOGGLE',
}
