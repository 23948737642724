import { combineReducers } from 'redux';
import constants from "../../constants";
import update from "immutability-helper";

// TODO REDUCERS Модального окна добавление контакта

const defaultDataTypeClients = {
  processSend: false
};

// Храним данные о типах клиента
const dataTypeClients = (state = defaultDataTypeClients, action) => {
  switch (action.type) {
    case constants.CLIENTS_TYPE_STATUS:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.CLIENTS_TYPE_STATUS_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.CLIENTS_TYPE_STATUS_ERROR:
      return update(state, {
        processSend: { $set: false },
      });
    case constants.NEW_CLIENTS_DEFAULT:
      return defaultDataTypeClients;
    default:
      return state;
  }
};

// Храним данные для отображения
const parseDataPole = (state = {}, action) => {
  switch (action.type) {
    case constants.CLIENTS_UPDATE_PROPERTY_POLE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.NEW_CLIENTS_DEFAULT:
      return {};
    default:
      return state;
  }
};

const defaultStatusModal = {
  toggle: false
};

// Храним данные модального окна
const statusModal = (state = defaultStatusModal, action) => {
  switch (action.type) {
    case constants.NEW_CLIENTS_TOGGLE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.NEW_CLIENTS_DEFAULT:
      return defaultStatusModal;
    default:
      return state;
  }
};

const defaultDoubleOpenModal = {
  toggle: false
};

// Открытие окна с подтверждением
const doubleOpenModal = (state = defaultDoubleOpenModal, action) => {
  switch (action.type) {
    case constants.NEW_CLIENTS_TOGGLE_BY_DOUBLE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.NEW_CLIENTS_DEFAULT:
      return defaultStatusModal;
    default:
      return state;
  }
}

const defaultChangeForm = {
  full_name_client: "",
  type_contact_client: "",
  telephone_client: "",
  email_client: "",
  telephone_country: ""
};

// Храним изменения в форме
const changeForm = (state = defaultChangeForm, action) => {
  switch (action.type) {
    case constants.NEW_CLIENTS_CHANGE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.NEW_CLIENTS_DEFAULT:
      return defaultChangeForm;
    default:
      return state;
  }
};

const defaultValidateForm = {
  full_name_client: false,
  type_contact_client: false,
  telephone_client: false,
  email_client: false,
};

// Храним состояние валидации
const validateForm = (state = defaultValidateForm, action) => {
  switch (action.type) {
    case constants.NEW_CLIENTS_VALIDATE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.NEW_CLIENTS_DEFAULT:
      return defaultValidateForm;
    default:
      return state;
  }
};

const defaultSendClientsForm = {
  processSend: false
};

// Храним состояние отправки формы
const sendClientsForm = (state = defaultSendClientsForm, action) => {
  switch (action.type) {
    case constants.NEW_CLIENTS_SEND:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.NEW_CLIENTS_SEND_SUCCESS:
      return update(state, {
        $set: {
          processSend: false,
          ...action.payload
        }
      });
    case constants.NEW_CLIENTS_SEND_ERROR:
      return update(state, {
        $set: {
          processSend: false,
          ...action.payload
        }
      });
    case constants.NEW_CLIENTS_DEFAULT:
      return defaultSendClientsForm;
    default:
      return state;
  }
};

export default combineReducers({
  statusModal,
  doubleOpenModal,
  changeForm,
  validateForm,
  sendClientsForm,
  dataTypeClients,
  parseDataPole
});