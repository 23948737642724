export default {
  OBJECT_TABLE_CHANGE: 'OBJECT_TABLE_CHANGE',
  OBJECT_TABLE_CHANGE_ALL: 'OBJECT_TABLE_CHANGE_ALL',

  OBJECT_TABLE_SAVE_SORTING_ON_PAGE_SEARCH: 'OBJECT_TABLE_SAVE_SORTING_ON_PAGE_SEARCH',
  OBJECT_TABLE_SAVE_SORTING_ON_PAGE_MY_OBJECT: 'OBJECT_TABLE_SAVE_SORTING_ON_PAGE_MY_OBJECT',
  OBJECT_TABLE_SAVE_SORTING_ON_PAGE_FREE_OBJECT: 'OBJECT_TABLE_SAVE_SORTING_ON_PAGE_FREE_OBJECT',
  OBJECT_TABLE_SAVE_SORTING_ON_PAGE_PRIVATE_OBJECT: 'OBJECT_TABLE_SAVE_SORTING_ON_PAGE_PRIVATE_OBJECT',
  OBJECT_TABLE_SAVE_SORTING_ON_PAGE_MODERATION_OBJECT: 'OBJECT_TABLE_SAVE_SORTING_ON_PAGE_MODERATION_OBJECT',

  OBJECT_TABLE: 'OBJECT_TABLE',
  OBJECT_TABLE_SUCCESS: 'OBJECT_TABLE_SUCCESS',
  OBJECT_TABLE_ERROR: 'OBJECT_TABLE_ERROR',

  OBJECT_TABLE_OPEN_USER: 'OBJECT_TABLE_OPEN_USER',
  OBJECT_TABLE_OPEN_USER_SUCCESS: 'OBJECT_TABLE_OPEN_USER_SUCCESS',
  OBJECT_TABLE_OPEN_USER_ERROR: 'OBJECT_TABLE_OPEN_USER_ERROR',

  OBJECT_TABLE_OPEN_USER_SHOW: 'OBJECT_TABLE_OPEN_USER_SHOW',

  OBJECT_TABLE_SAVE_OBJECT: 'OBJECT_TABLE_SAVE_OBJECT',
}
