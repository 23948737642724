export default {
  SEARCH_APPLICATIONS_IN_OBJECT_LOAD_FILTERS: 'SEARCH_APPLICATIONS_IN_OBJECT_LOAD_FILTERS',
  SEARCH_APPLICATIONS_IN_OBJECT_LOAD_FILTERS_ERROR: 'SEARCH_APPLICATIONS_IN_OBJECT_LOAD_FILTERS_ERROR',
  SEARCH_APPLICATIONS_IN_OBJECT_LOAD_FILTERS_SUCCESS: 'SEARCH_APPLICATIONS_IN_OBJECT_LOAD_FILTERS_SUCCESS',

  SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE: 'SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE',
  SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_ERROR: 'SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_ERROR',
  SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_SUCCESS: 'SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_SUCCESS',

  SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE: 'SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE',
  SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE_ERROR: 'SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE_ERROR',
  SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE_SUCCESS: 'SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE_SUCCESS',

  SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE_SOURCE: 'SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE_SOURCE',

  SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_CHANGE: 'SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_CHANGE',
  SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_CHANGE_ALL: 'SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_CHANGE_ALL',

  SEARCH_APPLICATIONS_IN_OBJECT_TABLE_SEARCH: 'SEARCH_APPLICATIONS_IN_OBJECT_TABLE_SEARCH',

  SEARCH_APPLICATIONS_IN_OBJECT_PARSE: 'SEARCH_APPLICATIONS_IN_OBJECT_PARSE',

  SEARCH_APPLICATIONS_IN_OBJECT_FILTERS_ACTION: 'SEARCH_APPLICATIONS_IN_OBJECT_FILTERS_ACTION',
  SEARCH_APPLICATIONS_IN_OBJECT_FILTERS_ACTION_TOGGLE: 'SEARCH_APPLICATIONS_IN_OBJECT_FILTERS_ACTION_TOGGLE',

  SEARCH_APPLICATIONS_IN_OBJECT_FILTERS_CHANGE: 'SEARCH_APPLICATIONS_IN_OBJECT_FILTERS_CHANGE',

  SEARCH_APPLICATIONS_IN_OBJECT_SAVE: 'SEARCH_APPLICATIONS_IN_OBJECT_SAVE',

  SEARCH_APPLICATIONS_TABLE_OPEN_USER_SHOW: 'SEARCH_APPLICATIONS_TABLE_OPEN_USER_SHOW',

  SEARCH_APPLICATIONS_TABLE_OPEN_USER: 'SEARCH_APPLICATIONS_TABLE_OPEN_USER',
  SEARCH_APPLICATIONS_TABLE_OPEN_USER_SUCCESS: 'SEARCH_APPLICATIONS_TABLE_OPEN_USER_SUCCESS',
  SEARCH_APPLICATIONS_TABLE_OPEN_USER_ERROR: 'SEARCH_APPLICATIONS_TABLE_OPEN_USER_ERROR',

  SEARCH_APPLICATIONS_IN_OBJECT_RESETS_DATA_FILTERS: 'SEARCH_APPLICATIONS_IN_OBJECT_RESETS_DATA_FILTERS',
  SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL: 'SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL'
}
