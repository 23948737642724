import { combineReducers } from "redux";
import constants from "../../../../constants";
import update from "immutability-helper";

const defaultCommercialData = {
  data: null
};

// Храним данные о рекламе
const commercialData = (state = defaultCommercialData, action) => {
  switch (action.type) {
    case constants.COMMERCIAL_DATA_INIT:
      return update(state, {
        $merge: action.payload
      });
    case constants.COMMERCIAL_DEFAULT:
      return defaultCommercialData;
    default:
      return state;
  }
};

const defaultCommercialRenderPole = {
  renderPole: []
};

// Храним данные для рендера полей
const commercialRenderPole = (state = defaultCommercialRenderPole, action) => {
  switch (action.type) {
    case constants.COMMERCIAL_POLE_INIT:
      return update(state, {
        renderPole: { $set: action.payload }
      });
    case constants.COMMERCIAL_DEFAULT:
      return defaultCommercialRenderPole;
    default:
      return state;
  }
};

const defaultCommercialDataInPole = {
  dataPole: {}
};

// Храним данные о полях
const commercialDataInPole = (state = defaultCommercialDataInPole, action) => {
  switch (action.type) {
    case constants.COMMERCIAL_DATA_POLE_INIT:
      return update(state, {
        dataPole: { $set: action.payload }
      });
    case constants.COMMERCIAL_DATA_POLE_CHANGE:
      return update(state, {
        dataPole: { $merge: action.payload }
      });
    case constants.COMMERCIAL_DEFAULT:
      return defaultCommercialDataInPole;
    default:
      return state;
  }
};

const defaultCommercialValidData = {
  validObject: {}
};

// Валидация активных полей которые имеют каждодневную выгрузку с датами
const commercialValidData = (state = defaultCommercialValidData, action) => {
  switch (action.type) {
    case constants.COMMERCIAL_VALID_DATA:
      return update(state, {
        validObject: { $merge: action.payload }
      });
    default:
      return state;
  }
};

export default combineReducers({
  commercialData,
  commercialRenderPole,
  commercialDataInPole,
  commercialValidData
});
