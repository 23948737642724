export default {
  // Наполнение данными
  COMMERCIAL_DATA_INIT: 'COMMERCIAL_DATA_INIT',

  // Рендер полей
  COMMERCIAL_POLE_INIT: 'COMMERCIAL_POLE_INIT',

  // Загрузка дефолтных данных
  COMMERCIAL_DATA_POLE_INIT: 'COMMERCIAL_DATA_POLE_INIT',
  // Меняем значения
  COMMERCIAL_DATA_POLE_CHANGE: 'COMMERCIAL_DATA_POLE_CHANGE',

  // Статус валидации
  COMMERCIAL_VALID_DATA: 'COMMERCIAL_VALID_DATA',

  // Сброс на дефолт
  COMMERCIAL_DEFAULT: 'COMMERCIAL_DEFAULT',
}
