import { combineReducers } from "redux";
import constants from "../../../constants";
import update from "immutability-helper";

const defaultFavouritesApplicationsDataTable = {
  processSend: true
};

/* Храним данные таблицы */
const favouritesApplicationsDataTable = (state = defaultFavouritesApplicationsDataTable, action) => {
  switch (action.type) {
    case constants.FAVOURITES_APPLICATIONS_TABLE_DATA:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.FAVOURITES_APPLICATIONS_TABLE_DATA_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.FAVOURITES_APPLICATIONS_TABLE_DATA_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

const defaultFavouritesApplicationsSaveSortInTable = {
  sortItem: "",
  columnSort: "",
};

const favouritesApplicationsSaveSortInTable = (state = defaultFavouritesApplicationsSaveSortInTable, action) => {
  switch (action.type) {
    case constants.FAVOURITES_APPLICATIONS_TABLE_SAVE_SORT:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultTableChange = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  loading: false,
  itemTable: "",
  data: []
};

const tableChange = (state = defaultTableChange, action) => {
  switch (action.type) {
    case constants.FAVOURITES_APPLICATIONS_TABLE_DATA:
      return update(state, {
        loading: { $set: true }
      });
    case constants.FAVOURITES_APPLICATIONS_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.FAVOURITES_APPLICATIONS_CHANGE_ALL:
      return update(state, {
        data: { $set: action.payload.data },
        pagination: { $set: action.payload.pagination },
        loading: { $set: false }
      });
    case constants.FAVOURITES_APPLICATIONS_RESETS_ALL:
      return defaultTableChange;
    default:
      return state;
  }
};

export default combineReducers({
  favouritesApplicationsDataTable,
  favouritesApplicationsSaveSortInTable,
  tableChange
});
