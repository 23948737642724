export default {
  MODAL_OBJECTS_TABLE: 'MODAL_OBJECTS_TABLE',
  MODAL_OBJECTS_TABLE_SUCCESS: 'MODAL_OBJECTS_TABLE_SUCCESS',
  MODAL_OBJECTS_TABLE_ERROR: 'MODAL_OBJECTS_TABLE_ERROR',

  MODAL_OBJECTS_RESETS_DATA: 'MODAL_OBJECTS_RESETS_DATA',
  MODAL_OBJECTS_TOGGLE: 'MODAL_OBJECTS_TOGGLE',
  MODAL_OBJECTS_SAVE: 'MODAL_OBJECTS_SAVE',

  MODAL_OBJECTS_TABLE_CHANGE: 'MODAL_OBJECTS_TABLE_CHANGE',
  MODAL_OBJECTS_TABLE_CHANGE_ALL: 'MODAL_OBJECTS_TABLE_CHANGE_ALL',

  MODAL_OBJECTS_TABLE_SAVE_SEND: 'MODAL_OBJECTS_TABLE_SAVE_SEND',
  MODAL_OBJECTS_TABLE_SAVE_SEND_SUCCESS: 'MODAL_OBJECTS_TABLE_SAVE_SEND_SUCCESS',
  MODAL_OBJECTS_TABLE_SAVE_SEND_ERROR: 'MODAL_OBJECTS_TABLE_SAVE_SEND_ERROR'
}
