import constants from "../../constants";
import update from "immutability-helper";
import {combineReducers} from "redux";


const modalPresentationToggle = (state = { modal: false }, action) => {
  switch (action.type) {
    case constants.MODAL_PRESENTATION_OPEN:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

export default combineReducers({
  modalPresentationToggle
});
