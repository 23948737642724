export default {
  OBJECT_PROPERTY_FILTER: 'OBJECT_PROPERTY_FILTER',
  OBJECT_PROPERTY_FILTER_SUCCESS: 'OBJECT_PROPERTY_FILTER_SUCCESS',
  OBJECT_PROPERTY_FILTER_ERROR: 'OBJECT_PROPERTY_FILTER_ERROR',

  OBJECT_PROPERTY_AUTO_COMPLETE: 'OBJECT_PROPERTY_AUTO_COMPLETE',
  OBJECT_PROPERTY_AUTO_COMPLETE_SUCCESS: 'OBJECT_PROPERTY_AUTO_COMPLETE_SUCCESS',
  OBJECT_PROPERTY_AUTO_COMPLETE_ERROR: 'OBJECT_PROPERTY_AUTO_COMPLETE_ERROR',

  OBJECT_PROPERTY_ADD_LOCATION: 'OBJECT_PROPERTY_ADD_LOCATION',

  OBJECT_PROPERTY_AUTO_COMPLETE_SOURCE: 'OBJECT_PROPERTY_AUTO_COMPLETE_SOURCE',

  OBJECT_PROPERTY_FILTER_FIXED: 'OBJECT_PROPERTY_FILTER_FIXED',
  OBJECT_PROPERTY_FILTER_RENDER: 'OBJECT_PROPERTY_FILTER_RENDER',
  OBJECT_PROPERTY_FILTER_RENDER_FIXED: 'OBJECT_PROPERTY_FILTER_RENDER_FIXED',
  OBJECT_PROPERTY_FILTER_UPDATE_POLE: 'OBJECT_PROPERTY_FILTER_UPDATE_POLE',

  OBJECT_PROPERTY_CHANGE_MAP: 'OBJECT_PROPERTY_CHANGE_MAP',

  OBJECT_PROPERTY_FILTER_CHANGE: 'OBJECT_PROPERTY_FILTER_CHANGE',
  OBJECT_PROPERTY_FILTER_ADD_GROUP: 'OBJECT_PROPERTY_FILTER_ADD_GROUP',
  OBJECT_PROPERTY_FILTER_VALIDATE: 'OBJECT_PROPERTY_FILTER_VALIDATE',

  OBJECT_PROPERTY_FILTER_SEND: 'OBJECT_PROPERTY_FILTER_SEND',
  OBJECT_PROPERTY_FILTER_SEND_SUCCESS: 'OBJECT_PROPERTY_FILTER_SEND_SUCCESS',
  OBJECT_PROPERTY_FILTER_SEND_ERROR: 'OBJECT_PROPERTY_FILTER_SEND_ERROR',

  OBJECT_PROPERTY_STATIC_FILTER: 'OBJECT_PROPERTY_STATIC_FILTER',
  OBJECT_PROPERTY_STATIC_FILTER_SUCCESS: 'OBJECT_PROPERTY_STATIC_FILTER_SUCCESS',
  OBJECT_PROPERTY_STATIC_FILTER_ERROR: 'OBJECT_PROPERTY_STATIC_FILTER_ERROR',

  OBJECT_PROPERTY_GEO: 'OBJECT_PROPERTY_GEO',
  OBJECT_PROPERTY_GEO_SUCCESS: 'OBJECT_PROPERTY_GEO_SUCCESS',
  OBJECT_PROPERTY_GEO_ERROR: 'OBJECT_PROPERTY_GEO_ERROR',
  OBJECT_PROPERTY_GEO_INSERT: 'OBJECT_PROPERTY_GEO_INSERT',

  OBJECT_PROPERTY_SEND: 'OBJECT_PROPERTY_SEND',
  OBJECT_PROPERTY_SEND_SUCCESS: 'OBJECT_PROPERTY_SEND_SUCCESS',
  OBJECT_PROPERTY_SEND_ERROR: 'OBJECT_PROPERTY_SEND_ERROR',

  OBJECT_PROPERTY_CHANGE_ATTRIBUTE: 'OBJECT_PROPERTY_CHANGE_ATTRIBUTE',

  OBJECT_PROPERTY_SAVE: 'OBJECT_PROPERTY_SAVE',

  OBJECT_PROPERTY_RESETS: 'OBJECT_PROPERTY_RESETS',
}
