export default {
  TOGGLE_MODAL_DELETE_BOOKMARK: 'TOGGLE_MODAL_DELETE_BOOKMARK',
  MODAL_DELETE_BOOKMARK_CHANGE: 'MODAL_DELETE_BOOKMARK_CHANGE',
  MODAL_DELETE_BOOKMARK_RESETS: 'MODAL_DELETE_BOOKMARK_RESETS',
  MODAL_DELETE_BOOKMARK_VALIDATE: 'MODAL_DELETE_BOOKMARK_VALIDATE',

  MODAL_DELETE_DATA: 'MODAL_DELETE_DATA',
  MODAL_DELETE_DATA_SUCCESS: 'MODAL_DELETE_DATA_SUCCESS',
  MODAL_DELETE_DATA_ERROR: 'MODAL_DELETE_DATA_ERROR',
}
