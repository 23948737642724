import { combineReducers } from "redux";
import constants from "../../../../constants";
import update from "immutability-helper";

const propertyApplicationsLoadForm = (state = {}, action) => {
  switch (action.type) {
    case constants.PROPERTY_APPLICATIONS_LOAD:
      return state;
    case constants.PROPERTY_APPLICATIONS_LOAD_SUCCESS:
      return action.payload;
    case constants.PROPERTY_APPLICATIONS_LOAD_ERROR:
      return state;
    case constants.PROPERTY_APPLICATIONS_RESTS_ALL:
      return {};
    default:
      return state;
  }
};

const propertyApplicationsLvlForm = (state = [], action) => {
  switch (action.type) {
    case constants.PROPERTY_APPLICATIONS_UPDATE_LVL:
      return update(state, {
        $set: action.payload
      });
    case constants.PROPERTY_APPLICATIONS_UPDATE_POLE:
      return update(state, {
        [action.payload.lvl]: {
          [action.payload.itemPole]: {
            $set: action.payload.pole
          }
        }
      });
    case constants.PROPERTY_APPLICATIONS_RESTS_ALL:
      return [];
    default:
      return state;
  }
};

const propertyApplicationsChangeForm = (state = { isRender: false }, action) => {
  switch (action.type) {
    case constants.PROPERTY_APPLICATIONS_LOAD_CHANGE:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });
    case constants.PROPERTY_APPLICATIONS_ADD_FORM:
      return update(state, {
        $merge: action.payload
      });
    case constants.PROPERTY_APPLICATIONS_RESTS_ALL:
      return {};
    default:
      return state;
  }
};

const propertyApplicationsValidateForm = (state = {}, action) => {
  switch (action.type) {
    case constants.PROPERTY_APPLICATIONS_LOAD_VALIDATE:
      return update(state, {
        $merge: { [action.payload.name]: action.payload.value }
      });
    case constants.PROPERTY_APPLICATIONS_RESTS_ALL:
      return {};
    default:
      return state;
  }
};

const defaultPropertyApplicationsAutoComplete = {
  processSend: false
};

const propertyApplicationsAutoComplete = (state = defaultPropertyApplicationsAutoComplete, action) => {
  switch (action.type) {
    case constants.PROPERTY_APPLICATIONS_AUTOCOMPLETE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.PROPERTY_APPLICATIONS_AUTOCOMPLETE_SUCCESS:
      return update(state, {
        $merge: {
          processSend: false,
          ...action.payload
        }
      });
    case constants.PROPERTY_APPLICATIONS_AUTOCOMPLETE_ERROR:
      return update(state, {
        $merge: {
          processSend: false,
          ...action.payload
        }
      });
    case constants.PROPERTY_APPLICATIONS_RESTS_ALL:
      return defaultPropertyApplicationsAutoComplete;
    default:
      return state;
  }
};

const objectPropertySourceAutoComplete = (state = {}, action) => {
  switch (action.type) {
    case constants.PROPERTY_APPLICATIONS_AUTOCOMPLETE_SOURCE:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

export default combineReducers({
  propertyApplicationsLoadForm,
  propertyApplicationsLvlForm,
  propertyApplicationsChangeForm,
  propertyApplicationsValidateForm,
  propertyApplicationsAutoComplete,
  objectPropertySourceAutoComplete
});
