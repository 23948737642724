import { combineReducers } from "redux";
import constants from "../../constants";
import update from "immutability-helper";

const mainReloadState = (state = { reload: false }, action) => {
  switch (action.type) {
    case constants.MAIN_MENU_DBCLICK:
      return action.payload;
    default:
      return state;
  }
};

let defaultMenu = {
  collapsed: false,
  openSubMenu: [],
  openMenu: []
};

const mainActionMenu = (state = defaultMenu, action) => {
  switch (action.type) {
    case constants.MAIN_MENU_ACTION:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });
    default:
      return state;
  }
};

const mainLoadFilters = (state = null, action) => {
  switch (action.type) {
    case constants.MAIN_LOAD_FILTERS:
      return state;
    case constants.MAIN_LOAD_FILTERS_SUCCESS:
      return action.payload;
    case constants.MAIN_LOAD_FILTERS_ERROR:
      return state;
    default:
      return state;
  }
};

const mainToggleModal = (state = { modal: false, itemFilter: null }, action) => {
  switch (action.type) {
    case constants.MAIN_MENU_MODAL_TOGGLE:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

const mainDeleteFilter = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.MAIN_MENU_DELETE_FILTER:
      return { processSend: true };
    case constants.MAIN_MENU_DELETE_FILTER_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.MAIN_MENU_DELETE_FILTER_ERROR:
      return { processSend: false };
    default:
      return state;
  }
};

export default combineReducers({
  mainReloadState,
  mainActionMenu,
  mainLoadFilters,
  mainToggleModal,
  mainDeleteFilter,
});
