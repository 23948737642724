import constants from '../../constants/index';
import { combineReducers } from "redux";
import update from "immutability-helper";

const defaultStatusAuth = {
  processSend: false
};

// Проверка токена
const statusAuth = (state = defaultStatusAuth, action) => {
  switch (action.type) {
    case constants.USER_STATUS_AUTH:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.USER_STATUS_AUTH_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.USER_STATUS_AUTH_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.USER_STATUS_LOGOUT_SUCCESS:
      return defaultStatusAuth;
    default:
      return state;
  }
};

const defaultLoginInStatus = {
  processSend: false
};

// Авторизация
const loginInStatus = (state = defaultLoginInStatus, action) => {
  switch (action.type) {
    case constants.USER_STATUS_LOGIN:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.USER_STATUS_LOGIN_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.USER_STATUS_LOGIN_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.USER_STATUS_LOGOUT_SUCCESS:
      return defaultLoginInStatus;
    default:
      return state;
  }
}

const defaultBitrixStatus = {
  processSend: true
};

// Статус проверки сделки
const bitrixStatus = (state = defaultBitrixStatus, action) => {
  switch (action.type) {
    case constants.USER_STATUS_BITRIX:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.USER_STATUS_BITRIX_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.USER_STATUS_BITRIX_ERROR:
      return update(state, {
        processSend: { $set: false },
      });
    default:
      return state;
  }
};

const defaultBitrixLead = {
  processSend: true
};

// Статус лида
const bitrixLead = (state = defaultBitrixLead, action) => {
  switch (action.type) {
    case constants.USER_STATUS_LEAD_OR_DEAL_BITRIX:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.USER_STATUS_LEAD_OR_DEAL_BITRIX_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.USER_STATUS_LEAD_OR_DEAL_BITRIX_ERROR:
      return update(state, {
        processSend: { $set: false },
      });
    default:
      return state;
  }
};

const defaultLogoutStatus = {
  processSend: false
};

// Разовтаризация
const logoutStatus = (state = defaultLogoutStatus, action) => {
  switch (action.type) {
    case constants.USER_STATUS_LOGOUT:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.USER_STATUS_LOGOUT_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.USER_STATUS_LOGOUT_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

const defaultPropertyUser = {
  isAuth: false,
  redirectStatus: false,
  loadPage: true
};

// Данные пользователя
const propertyUser = (state = defaultPropertyUser, action) => {
  switch (action.type) {
    case constants.USER_PROPERTY_UPDATE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.USER_CHECK_TOKEN:
      return update(state, {
        $set: {
          redirectStatus: true,
          isAuth: false
        },
      });
    case constants.USER_STATUS_LOGOUT_SUCCESS:
      return defaultPropertyUser;
    default:
      return state;
  }
};

const defaultForm = {
  login: '',
  pass: '',
  checkbox: false,
};

const formChange = (state = defaultForm, action) => {
  switch (action.type) {
    case constants.USER_FORM_CHANGE:
      const { value, name } = action.payload;

      return update(state, {
        [name]: { $set: value }
      });
    default:
      return state;
  }
};

const validateForm = {
  login: false,
  pass: false
};

const validate = (state = validateForm, action) => {
  switch (action.type) {
    case constants.USER_STATUS_AUTH_ERROR:
      return update(state, {
        login: { $set: true },
        pass: { $set: true }
      });
    case constants.USER_FORM_VALIDATE:
      const { field, error } = action.payload;

      return update(state, {
        [field]: { $set: error }
      });
    default:
      return state;
  }
};

export default combineReducers({
  propertyUser,
  statusAuth,
  bitrixStatus,
  bitrixLead,
  loginInStatus,
  logoutStatus,
  validate,
  formChange
});
