import { combineReducers } from "redux";
import constants from "../../../constants";
import update from "immutability-helper";

const tableShowUserInfo = (state = [], action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_TABLE_OPEN_USER_SHOW:
      return update(state, {
        $push: action.payload
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL:
      return [];
    default:
      return state;
  }
};

const tableShowUserInfoStatus = (state = {}, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_TABLE_OPEN_USER:
      return state;
    case constants.SEARCH_APPLICATIONS_TABLE_OPEN_USER_SUCCESS:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_APPLICATIONS_TABLE_OPEN_USER_ERROR:
      return state;
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL:
      return {};
    default:
      return state;
  }
};

/* Храним фильтры */
const searchApplicationsInObjectLoadFilters = (state = { processSend: true }, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_LOAD_FILTERS:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_LOAD_FILTERS_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_LOAD_FILTERS_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

/* Хранение данных для таблицы */
const dataTableSearch = (state = {}, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE:
      return state;
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_SUCCESS:
      return action.payload;
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_ERROR:
      return update(state, {
        error: { $set: true }
      });
    default:
      return state;
  }
};

const defaultTableSortingSave = {
  sortItem: "",
  columnSort: "",
};

const tableSortingSave = (state = defaultTableSortingSave, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_TABLE_SEARCH:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultTableChange = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  loading: true,
  data: []
};

/* Хранение данных для рендера таблице */
const tableChange = (state = defaultTableChange, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE:
      return update(state, {
        loading: { $set: true }
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_LOAD_TABLE_CHANGE_ALL:
      return update(state, {
        data: { $set: action.payload.data },
        pagination: { $set: action.payload.pagination },
        loading: { $set: false }
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL:
      return defaultTableChange;
    default:
      return state;
  }
};

/* Храним фильтры для рендера */
const searchApplicationsInObjectParseFilters = (state = [], action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_PARSE:
      return action.payload;
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL:
      return [];
    default:
      return state;
  }
};

/* Храним значения фильтров */
const searchApplicationsInObjectChange = (state = {}, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_FILTERS_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_DATA_FILTERS:
      return {};
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL:
      return {};
    default:
      return state;
  }
};

/* Храним значения активных фильтров */
const searchApplicationsInObjectAction = (state = {}, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_FILTERS_ACTION:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_DATA_FILTERS:
      return {};
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL:
      return {};
    default:
      return state;
  }
};

/* Видимость полей */
const searchApplicationsInObjectActionToggle = (state = {}, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_FILTERS_ACTION_TOGGLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_DATA_FILTERS:
      return {};
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL:
      return {};
    default:
      return state;
  }
};

const defaultSearchApplicationsInObjectSaveBookmark = {
  id_request: [],
};

/* Хранение закладок для объекта из заявок */
const searchApplicationsInObjectSaveBookmark = (state = defaultSearchApplicationsInObjectSaveBookmark, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_SAVE:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL:
      return defaultSearchApplicationsInObjectSaveBookmark;
    default:
      return state;
  }
};

const defaultSearchApplicationsInObjectAutocomplete = {
  processSend: false
};

// Данные автокомплита
const searchApplicationsInObjectAutocomplete = (state = defaultSearchApplicationsInObjectAutocomplete, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE_SUCCESS:
      return update(state, {
        $merge: {
          processSend: false,
          ...action.payload
        }
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE_ERROR:
      return update(state, {
        $merge: {
          processSend: false,
          ...action.payload
        }
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL:
      return defaultSearchApplicationsInObjectAutocomplete;
    default:
      return state;
  }
};

// Сохранение полей
const searchApplicationsInObjectAutocompleteSource = (state = {}, action) => {
  switch (action.type) {
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_AUTOCOMPLETE_SOURCE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.SEARCH_APPLICATIONS_IN_OBJECT_RESETS_ALL:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  searchApplicationsInObjectLoadFilters,
  searchApplicationsInObjectParseFilters,
  searchApplicationsInObjectChange,
  searchApplicationsInObjectAction,
  searchApplicationsInObjectActionToggle,
  searchApplicationsInObjectSaveBookmark,
  dataTableSearch,
  tableChange,
  tableSortingSave,
  searchApplicationsInObjectAutocomplete,
  searchApplicationsInObjectAutocompleteSource,
  tableShowUserInfo,
  tableShowUserInfoStatus,
});
