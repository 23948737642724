import { combineReducers } from "redux";
import constants from "../../constants";
import update from "immutability-helper";

const defaultToggleModalDeleteBookmark = {
  modal: false
};

const toggleModalDeleteBookmark = (state = defaultToggleModalDeleteBookmark, action) => {
  switch (action.type) {
    case constants.TOGGLE_MODAL_DELETE_BOOKMARK:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const modalDeleteBookmarkValue = (state = {}, action) => {
  switch (action.type) {
    case constants.MODAL_DELETE_BOOKMARK_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.MODAL_DELETE_BOOKMARK_RESETS:
      return {};
    default:
      return state;
  }
};

const modalDeleteBookmarkValidate = (state = {}, action) => {
  switch (action.type) {
    case constants.MODAL_DELETE_BOOKMARK_VALIDATE:
      return update(state, {
        $merge: action.payload
      });
    case constants.MODAL_DELETE_BOOKMARK_RESETS:
      return {};
    default:
      return state;
  }
};

const defaultFavoritesDelete = {
  processSend: false
};

const modalDeleteBookmarkStatus = (state = defaultFavoritesDelete, action) => {
  switch (action.type) {
    case constants.MODAL_DELETE_DATA:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.MODAL_DELETE_DATA_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.MODAL_DELETE_DATA_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

export default combineReducers({
  toggleModalDeleteBookmark,
  modalDeleteBookmarkValue,
  modalDeleteBookmarkValidate,
  modalDeleteBookmarkStatus,
});
