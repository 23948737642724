// TODO CONSTANTS Страницы импорта

export default {
  // Процесс загрузки данных
  IMPORTS_DATA_TABLE: 'IMPORTS_DATA_TABLE',
  IMPORTS_DATA_TABLE_SUCCESS: 'IMPORTS_DATA_TABLE_SUCCESS',
  IMPORTS_DATA_TABLE_ERROR: 'IMPORTS_DATA_TABLE_ERROR',

  // Изменение свойств таблицы
  IMPORTS_CHANGE_PROPERTY_IN_TABLE: 'IMPORTS_CHANGE_PROPERTY_IN_TABLE',

  /***/
  IMPORTS_UPDATE_SOURCE: 'IMPORTS_UPDATE_SOURCE',
  IMPORTS_UPDATE_SOURCE_SUCCESS: 'IMPORTS_UPDATE_SOURCE_SUCCESS',
  IMPORTS_UPDATE_SOURCE_ERROR: 'IMPORTS_UPDATE_SOURCE_ERROR',
  /***/

  // Сброс данных до изначальных
  IMPORTS_DEFAULT: 'IMPORTS_DEFAULT',
};
