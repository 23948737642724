export default {
  FAVOURITES_APPLICATIONS_TABLE_DATA: 'FAVOURITES_APPLICATIONS_TABLE_DATA',
  FAVOURITES_APPLICATIONS_TABLE_DATA_SUCCESS: 'FAVOURITES_APPLICATIONS_TABLE_DATA_SUCCESS',
  FAVOURITES_APPLICATIONS_TABLE_DATA_ERROR: 'FAVOURITES_APPLICATIONS_TABLE_DATA_ERROR',

  FAVOURITES_APPLICATIONS_TABLE_SAVE_SORT: 'FAVOURITES_APPLICATIONS_TABLE_SAVE_SORT',

  FAVOURITES_APPLICATIONS_CHANGE: 'FAVOURITES_APPLICATIONS_CHANGE',
  FAVOURITES_APPLICATIONS_CHANGE_ALL: 'FAVOURITES_APPLICATIONS_CHANGE_ALL',
  FAVOURITES_APPLICATIONS_RESETS_ALL: 'FAVOURITES_APPLICATIONS_RESETS_ALL',
}
