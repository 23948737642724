import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import root from '../reducers';
import middleware from "../middleware/api";
import { routerMiddleware } from 'react-router-redux';
import history from '../history';

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(
    thunk,
    routerMiddleware(history),
    middleware
  ),
);

export const store =  createStore(
  combineReducers({
    root
  }), enhancer
);

export default store;
