export default {
  USER_FORM_VALIDATE: 'USER_FORM_VALIDATE',

  USER_FORM_CHANGE: 'USER_FORM_CHANGE',

  // Проверка токена
  USER_STATUS_AUTH: 'USER_STATUS_AUTH',
  USER_STATUS_AUTH_SUCCESS: 'USER_STATUS_AUTH_SUCCESS',
  USER_STATUS_AUTH_ERROR: 'USER_STATUS_AUTH_ERROR',

  // Авторизация
  USER_STATUS_LOGIN: 'USER_STATUS_LOGIN',
  USER_STATUS_LOGIN_SUCCESS: 'USER_STATUS_LOGIN_SUCCESS',
  USER_STATUS_LOGIN_ERROR: 'USER_STATUS_LOGIN_ERROR',

  // Разовтаризация
  USER_STATUS_LOGOUT: 'USER_STATUS_LOGOUT',
  USER_STATUS_LOGOUT_SUCCESS: 'USER_STATUS_LOGOUT_SUCCESS',
  USER_STATUS_LOGOUT_ERROR: 'USER_STATUS_LOGOUT_ERROR',

  // Проверка сделки
  USER_STATUS_BITRIX: 'USER_STATUS_BITRIX',
  USER_STATUS_BITRIX_SUCCESS: 'USER_STATUS_BITRIX_SUCCESS',
  USER_STATUS_BITRIX_ERROR: 'USER_STATUS_BITRIX_ERROR',

  // Проверка лида
  USER_STATUS_LEAD_OR_DEAL_BITRIX: 'USER_STATUS_LEAD_OR_DEAL_BITRIX',
  USER_STATUS_LEAD_OR_DEAL_BITRIX_SUCCESS: 'USER_STATUS_LEAD_OR_DEAL_BITRIX_SUCCESS',
  USER_STATUS_LEAD_OR_DEAL_BITRIX_ERROR: 'USER_STATUS_LEAD_OR_DEAL_BITRIX_ERROR',

  USER_PROPERTY_UPDATE: 'USER_PROPERTY_UPDATE',

  USER_CHECK_TOKEN: 'USER_CHECK_TOKEN',
};
