// TODO CONSTANTS Страницы офисов и сотрудников офиса

export default {
  // Процесс загрузки данных
  PROPERTY_OFFICE: 'PROPERTY_OFFICE',
  PROPERTY_OFFICE_SUCCESS: 'PROPERTY_OFFICE_SUCCESS',
  PROPERTY_OFFICE_ERROR: 'PROPERTY_OFFICE_ERROR',

  // Сохранение сортировки в таблицах офисов и сотрудников
  PROPERTY_OFFICE_SAVE_SORT_TABLE_OFFICE: 'PROPERTY_OFFICE_SAVE_SORT_TABLE_OFFICE',
  PROPERTY_OFFICE_SAVE_SORT_TABLE_WORKERS: 'PROPERTY_OFFICE_SAVE_SORT_TABLE_WORKERS',

  // Изменение свойств таблицы
  PROPERTY_OFFICE_CHANGE_PROPERTY_IN_TABLE: 'PROPERTY_OFFICE_CHANGE_PROPERTY_IN_TABLE',

  // Изменение в модальном окне редактирования роли у сотрудника офиса
  PROPERTY_OFFICE_CHANGE_MODAL: 'PROPERTY_OFFICE_CHANGE_MODAL',

  // Состояние отправки новых свойств для работника офиса
  PROPERTY_OFFICE_MODAL_EDIT_ITEM_WORKER: 'PROPERTY_OFFICE_MODAL_EDIT_ITEM_WORKER',
  PROPERTY_OFFICE_MODAL_EDIT_ITEM_WORKER_SUCCESS: 'PROPERTY_OFFICE_MODAL_EDIT_ITEM_WORKER_SUCCESS',
  PROPERTY_OFFICE_MODAL_EDIT_ITEM_WORKER_ERROR: 'PROPERTY_OFFICE_MODAL_EDIT_ITEM_WORKER_ERROR',

  // Сброс данных до изначальных
  PROPERTY_OFFICE_DEFAULT: 'PROPERTY_OFFICE_DEFAULT',
};
