export default {
  FILTER_APPLICATION_LOAD: 'FILTER_APPLICATION_LOAD',
  FILTER_APPLICATION_LOAD_SUCCESS: 'FILTER_APPLICATION_LOAD_SUCCESS',
  FILTER_APPLICATION_LOAD_ERROR: 'FILTER_APPLICATION_LOAD_ERROR',

  FILTER_APPLICATION_AUTO_COMPLETE: 'FILTER_APPLICATION_AUTO_COMPLETE',
  FILTER_APPLICATION_AUTO_COMPLETE_SUCCESS: 'FILTER_APPLICATION_AUTO_COMPLETE_SUCCESS',
  FILTER_APPLICATION_AUTO_COMPLETE_ERROR: 'FILTER_APPLICATION_AUTO_COMPLETE_ERROR',

  FILTER_APPLICATION_AUTO_COMPLETE_SOURCE: 'FILTER_APPLICATION_AUTO_COMPLETE_SOURCE',

  FILTER_APPLICATION_AUTO_COMPLETE_COMBINE: 'FILTER_APPLICATION_AUTO_COMPLETE_COMBINE',
  FILTER_APPLICATION_AUTO_COMPLETE_COMBINE_SUCCESS: 'FILTER_APPLICATION_AUTO_COMPLETE_COMBINE_SUCCESS',
  FILTER_APPLICATION_AUTO_COMPLETE_COMBINE_ERROR: 'FILTER_APPLICATION_AUTO_COMPLETE_COMBINE_ERROR',

  FILTER_APPLICATION_CHANGE: 'FILTER_APPLICATION_CHANGE',
  FILTER_APPLICATION_FILTER_UPDATE: 'FILTER_APPLICATION_FILTER_UPDATE',
  FILTER_APPLICATION_FILTER_ACTION: 'FILTER_APPLICATION_FILTER_ACTION',

  FILTER_APPLICATION_SAVE_PARAMS_MODAL: 'FILTER_APPLICATION_SAVE_PARAMS_MODAL',

  FILTER_APPLICATION_SAVE_PARAMS_MODAL_CHANGE: 'FILTER_APPLICATION_SAVE_PARAMS_MODAL_CHANGE',
  FILTER_APPLICATION_SAVE_PARAMS_MODAL_VALIDATE: 'FILTER_APPLICATION_SAVE_PARAMS_MODAL_VALIDATE',

  FILTER_APPLICATION_SAVE_PARAMS_FILTER: 'FILTER_APPLICATION_SAVE_PARAMS_FILTER',
  FILTER_APPLICATION_SAVE_PARAMS_FILTER_SUCCESS: 'FILTER_APPLICATION_SAVE_PARAMS_FILTER_SUCCESS',
  FILTER_APPLICATION_SAVE_PARAMS_FILTER_ERROR: 'FILTER_APPLICATION_SAVE_PARAMS_FILTER_ERROR',

  FILTER_APPLICATION_SAVE_PARAMS_MODAL_RESETS_ALL: 'FILTER_APPLICATION_SAVE_PARAMS_MODAL_RESETS_ALL',

  FILTER_APPLICATION_RESETS_ALL: 'FILTER_APPLICATION_RESETS_ALL',
}
