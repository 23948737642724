import { combineReducers } from 'redux';
import constants from "../../../constants";
import update from "immutability-helper";

const showObjectLoad = (state = { processLoad: false }, action) => {
  switch (action.type) {
    case constants.SHOW_OBJECT_LOAD:
      return update(state, {
        processLoad: { $set: true }
      });
    case constants.SHOW_OBJECT_LOAD_SUCCESS:
      return update(state, {
        processLoad: { $set: false },
        $merge: action.payload
      });
    case constants.SHOW_OBJECT_LOAD_ERROR:
      return update(state, {
        processLoad: { $set: false }
      });
    default:
      return state;
  }
};

const showObjectParse = (state = null, action) => {
  switch (action.type) {
    case constants.SHOW_OBJECT_PARSE:
      return action.payload;
    case constants.SHOW_OBJECT_RESETS_ALL:
      return null;
    default:
      return state;
  }
};

const defaultShowObjectStateModal = {
  images: null,
  modal: false
};

const showObjectStateModal = (state = defaultShowObjectStateModal, action) => {
  switch (action.type) {
    case constants.SHOW_OBJECT_PREVIEW_TOGGLE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

export default combineReducers({
  showObjectLoad,
  showObjectParse,
  showObjectStateModal
});
