export default {
  NEW_APPLICATIONS_SEND: 'NEW_APPLICATIONS_SEND',
  NEW_APPLICATIONS_SEND_SUCCESS: 'NEW_APPLICATIONS_SEND_SUCCESS',
  NEW_APPLICATIONS_SEND_ERROR: 'NEW_APPLICATIONS_SEND_ERROR',

  EDIT_APPLICATIONS_LOAD: 'EDIT_APPLICATIONS_LOAD',
  EDIT_APPLICATIONS_LOAD_SUCCESS: 'EDIT_APPLICATIONS_LOAD_SUCCESS',
  EDIT_APPLICATIONS_LOAD_ERROR: 'EDIT_APPLICATIONS_LOAD_ERROR',

  SAVE_PAGE_APPLICATION: 'SAVE_PAGE_APPLICATION',
  SAVE_PAGE_APPLICATION_DEFAULT: 'SAVE_PAGE_APPLICATION_DEFAULT',
}
