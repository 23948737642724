export default {
  OBJECT_MAP_FILTER_STATE: 'OBJECT_MAP_FILTER_STATE',

  OBJECT_MAP_SAVE_POINTS: 'OBJECT_MAP_SAVE_POINTS',
  MAP_SAVE_OBJECTS_GROUP: 'MAP_SAVE_OBJECTS_GROUP',

  MAP_SAVE_OBJECTS_ID: 'MAP_SAVE_OBJECTS_ID',
  MAP_SAVE_OBJECTS_ID_NEW: 'MAP_SAVE_OBJECTS_ID_NEW',
  MAP_SAVE_OBJECTS_ID_RESETS: 'MAP_SAVE_OBJECTS_ID_RESETS',

  OBJECT_MAP_ADD_MAP_ITEM_AND_DRAW_CHANGE: 'OBJECT_MAP_ADD_MAP_ITEM_AND_DRAW_CHANGE',
  OBJECT_MAP_ITEM_OBJECT_POPUP: 'OBJECT_MAP_ITEM_OBJECT_POPUP',

  OBJECT_MAP_CHANGE_MAP: 'OBJECT_MAP_CHANGE_MAP',
  OBJECT_MAP_ADD_BOUNDS: 'OBJECT_MAP_ADD_BOUNDS',

  MAP_RESETS_ALL: 'MAP_RESETS_ALL',
}
