import filterApplication from "./filterApplication";
import tables from "./tables";

export default {
  SEARCH_APPLICATIONS_TOGGLE: 'SEARCH_APPLICATIONS_TOGGLE',
  SEARCH_APPLICATIONS_SAVE_PAGE: 'SEARCH_APPLICATIONS_SAVE_PAGE',

  ...filterApplication,
  ...tables,
}
