import { combineReducers } from "redux";
import constants from "../../../constants";
import update from "immutability-helper";

const defaultObjectPropertyLoad = {
  process: false,
};

const objectPropertyLoad = (state = defaultObjectPropertyLoad, action) => {
  switch (action.type) {
    case constants.NEW_OBJECT_LOAD:
      return update(state, {
        process: { $set: true }
      });
    case constants.NEW_OBJECT_LOAD_SUCCESS:
      return update(state, {
        process: { $set: false },
        $merge: action.payload
      });
    case constants.NEW_OBJECT_LOAD_ERROR:
      return update(state, {
        process: { $set: false }
      });
    case constants.NEW_OBJECT_LOAD_RESET:
      return defaultObjectPropertyLoad;
    case constants.OBJECT_PROPERTY_CHANGE_ATTRIBUTE:
      return {
        ...state,
        object: {
          ...state.object,
          [action.payload.fieldName]: action.payload.value
        }
      };
    default:
      return state;
  }
};

const defaultNewObjectShowValid = {
  active: false
};

const newObjectShowValid = (state = defaultNewObjectShowValid, action) => {
  switch (action.type) {
    case constants.NEW_OBJECT_SHOW_VALID:
      return action.payload;
    case constants.NEW_OBJECT_LOAD_RESET:
      return defaultNewObjectShowValid;
    default:
      return state;
  }
};

const defaultNewObjectProcessSend = {
  processSend: false
};

const newObjectProcessSend = (state = defaultNewObjectProcessSend, action) => {
  switch (action.type) {
    case constants.NEW_OBJECT_SEND:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.NEW_OBJECT_SEND_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.NEW_OBJECT_SEND_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.NEW_OBJECT_LOAD_RESET:
      return defaultNewObjectProcessSend;
    default:
      return state;
  }
};

const defaultSaveStatePageObject = {
  activeTab: null,
  form: {
    objectPropertyFilterChange: null,
    objectPropertySourceAutoComplete: null,
    objectPropertyFilterChangeMap: null,
    objectPhotoFiles: null,
    objectVideoAll: null,
    commercialRenderPole: null,
    commercialDataInPole: null,
  },
  application: {
    searchApplicationsInObjectChange: null,
    searchApplicationsInObjectAction: null,
    searchApplicationsInObjectSaveBookmark: null,
    searchApplicationsInObjectAutocompleteSource: null
  }
}

const saveStatePageObject = (state = defaultSaveStatePageObject, action) => {
  switch (action.type) {
    case constants.SAVE_PAGE_OBJECT:
      return update(state, {
        $merge: action.payload
      });
    case constants.SAVE_PAGE_OBJECT_DEFAULT:
      return defaultSaveStatePageObject;
    default:
      return state;
  }
}

export default combineReducers({
  objectPropertyLoad,
  newObjectProcessSend,
  newObjectShowValid,
  saveStatePageObject,
});
