import constants from "../../../constants";
import update from "immutability-helper";
import { combineReducers } from "redux";

const favouritesObjectsLoadDataTable = (state = { processSend: true }, action) => {
  switch (action.type) {
    case constants.FAVOURITES_OBJECT_TABLE_DATA:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.FAVOURITES_OBJECT_TABLE_DATA_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.FAVOURITES_OBJECT_TABLE_DATA_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.FAVOURITES_OBJECT_RESETS_ALL:
      return { processSend: true };
    default:
      return state;
  }
};

const defaultFavouritesObjectsToggleMap = {
  isMap: false
};

const favouritesObjectsToggleMap = (state = defaultFavouritesObjectsToggleMap, action) => {
  switch (action.type) {
    case constants.FAVOURITES_OBJECT_TOGGLE:
      return update(state, {
        isMap: { $set: action.payload }
      });
    case constants.FAVOURITES_OBJECT_RESETS_ALL:
      return defaultFavouritesObjectsToggleMap;
    default:
      return state;
  }
};

const defaultFavouritesObjectsSaveSortInTable = {
  sortItem: "",
  columnSort: "",
};

const favouritesObjectsSaveSortInTable = (state = defaultFavouritesObjectsSaveSortInTable, action) => {
  switch (action.type) {
    case constants.FAVOURITES_OBJECT_TABLE_SAVE_SORT:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultFavouritesObjectsSaveMap = {
  mapBounds: {
    northWest: [],
    southEast: [],
    centerMap: [55.019331, 82.939752],
    mapZoom: 13,
    isErrorTile: false,
  },
  addPoints: {
    points: []
  }
};

const favouritesObjectsSaveMap = (state = defaultFavouritesObjectsSaveMap, action) => {
  switch (action.type) {
    case constants.FAVOURITES_OBJECT_TABLE_SAVE_MAP:
      return update(state, {
        $merge: action.payload
      });
    case constants.FAVOURITES_OBJECT_RESETS_ALL:
      return defaultFavouritesObjectsSaveMap;
    default:
      return state;
  }
};

const defaultTableChange = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  loading: false,
  itemTable: "",
  data: []
};

const tableChange = (state = defaultTableChange, action) => {
  switch (action.type) {
    case constants.FAVOURITES_OBJECT_TABLE_DATA:
      return update(state, {
        loading: { $set: true }
      });
    case constants.FAVOURITES_OBJECT_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.FAVOURITES_OBJECT_CHANGE_ALL:
      return update(state, {
        data: { $set: action.payload.data },
        pagination: { $set: action.payload.pagination },
        loading: { $set: false }
      });
    case constants.FAVOURITES_OBJECT_RESETS_ALL:
      return defaultTableChange;
    default:
      return state;
  }
};

const tableShowUserInfo = (state = [], action) => {
  switch (action.type) {
    case constants.FAVOURITES_OBJECT_TABLE_OPEN_USER_SHOW:
      return update(state, {
        $push: action.payload
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return [];
    case constants.FAVOURITES_OBJECT_RESETS_ALL:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  favouritesObjectsSaveSortInTable,
  favouritesObjectsLoadDataTable,
  favouritesObjectsToggleMap,
  tableChange,
  tableShowUserInfo,
  favouritesObjectsSaveMap,
});
