import modalSuccess from "./modalSuccess";

export default {
  // Статус загрузки ответственных
  STATUS_WORKER: 'STATUS_WORKER',
  STATUS_WORKER_SUCCESS: 'STATUS_WORKER_SUCCESS',
  STATUS_WORKER_ERROR: 'STATUS_WORKER_ERROR',

  // Статус загрузки информации об ответственном
  STATUS_RESPONSIBLE: 'STATUS_RESPONSIBLE',
  STATUS_RESPONSIBLE_SUCCESS: 'STATUS_RESPONSIBLE_SUCCESS',
  STATUS_RESPONSIBLE_ERROR: 'STATUS_RESPONSIBLE_ERROR',

  // Получаем свойства
  GET_PROPERTY: 'GET_PROPERTY',
  GET_PROPERTY_SUCCESS: 'GET_PROPERTY_SUCCESS',
  GET_PROPERTY_ERROR: 'GET_PROPERTY_ERROR',

  ...modalSuccess
}