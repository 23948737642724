// TODO CONSTANTS Страницы значений словаря

export default {
  // Сохраняем глобальные значения
  LIST_VALUE_GLOBAL_SAVE: 'LIST_VALUE_GLOBAL_SAVE',

  // Статус загрузки значений
  LIST_VALUE_DATA_TABLE: 'LIST_VALUE_DATA_TABLE',
  LIST_VALUE_DATA_TABLE_SUCCESS: 'LIST_VALUE_DATA_TABLE_SUCCESS',
  LIST_VALUE_DATA_TABLE_ERROR: 'LIST_VALUE_DATA_TABLE_ERROR',

  // Изменение свойств таблицы
  LIST_VALUE_CHANGE_PROPERTY_IN_TABLE: 'LIST_VALUE_CHANGE_PROPERTY_IN_TABLE',

  // Отслеживаем изменения в модальном окне на удаление значения
  LIST_VALUE_TOGGLE_CONFIRM_DELETE: 'LIST_VALUE_TOGGLE_CONFIRM_DELETE',

  // Статус удаления значения
  LIST_VALUE_DELETE: 'LIST_VALUE_DELETE',
  LIST_VALUE_DELETE_SUCCESS: 'LIST_VALUE_DELETE_SUCCESS',
  LIST_VALUE_DELETE_ERROR: 'LIST_VALUE_DELETE_ERROR',

  // Отслеживаем изменения в модальном окне на редактирование/создание значения
  LIST_VALUE_EDIT_TOGGLE: 'LIST_VALUE_EDIT_TOGGLE',
  // Отслеживаем изменения в модальном окне в полях на редактирование/создание значения
  LIST_VALUE_EDIT_CHANGE: 'LIST_VALUE_EDIT_CHANGE',
  // Сброс полей в модальном окне на редактирование/создание значения
  LIST_VALUE_EDIT_CHANGE_DEFAULT: 'LIST_VALUE_EDIT_CHANGE_DEFAULT',
  // Валидируем поля в модальном окне на редактирование/создание значения
  LIST_VALUE_EDIT_VALIDATE: 'LIST_VALUE_EDIT_VALIDATE',
  // Сброс полей в модальном окне на редактирование/создание значения
  LIST_VALUE_EDIT_VALIDATE_DEFAULT: 'LIST_VALUE_EDIT_VALIDATE_DEFAULT',

  // Добавление нового свойства
  LIST_VALUE_ADD_PROPERTY: 'LIST_VALUE_ADD_PROPERTY',
  LIST_VALUE_ADD_PROPERTY_SUCCESS: 'LIST_VALUE_ADD_PROPERTY_SUCCESS',
  LIST_VALUE_ADD_PROPERTY_ERROR: 'LIST_VALUE_ADD_PROPERTY_ERROR',

  // Сброс на дефолтные настройки
  LIST_VALUE_DEFAULT_DATA: 'LIST_VALUE_DEFAULT_DATA'
}
