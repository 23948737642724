import constants from "../../constants";
import update from "immutability-helper";
import { combineReducers } from "redux";

const defaultToggleModalRedirectToClient = {
  modal: false,
  id: null
};

// Открытие/закрытие модального окна
const toggleModalRedirectToClient = (state = defaultToggleModalRedirectToClient, action) => {
  switch (action.type) {
    case constants.MODAL_REDIRECT_TO_CLIENT_TOGGLE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultToggleModalRedirectToClientGetLink = {
  processSend: false
}

// Процесс и хранение линка для редиректа на битрикс
const toggleModalRedirectToClientGetLink = (state = defaultToggleModalRedirectToClientGetLink, action) => {
  switch (action.type) {
    case constants.MODAL_REDIRECT_TO_CLIENT_SEND:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.MODAL_REDIRECT_TO_CLIENT_SEND_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.MODAL_REDIRECT_TO_CLIENT_SEND_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

export default combineReducers({
  toggleModalRedirectToClient,
  toggleModalRedirectToClientGetLink
});