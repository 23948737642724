import login from "./login";
import main from "./main";
import clients from "./clients";
import object from "./object";
import applications from "./applications";
import source from "./source";
import modalWorkers from "./modalWorkers";
import modalDeleteBookmark from "./modalDeleteBookmark";
import modalRedirectToClients from "./modalRedirectToClients";
import modalDoubleObjects from "./modalDoubleObjects";
import newClients from "./newClients";
import common from "./common";
import bitrixSearchObject from "./bitrixSearchObject";
import publicConstants from "./public";
import modalPresentation from "./modalPresentation";

/* Для теста потом удалить */
import propertyOffice from "./propertyOffice";

export const constants = {
  ...login,
  ...main,
  ...propertyOffice,
  ...clients,
  ...source,
  ...object,
  ...applications,
  ...modalWorkers,
  ...modalDeleteBookmark,
  ...modalRedirectToClients,
  ...modalDoubleObjects,
  ...newClients,
  ...common,
  ...bitrixSearchObject,
  ...publicConstants,
  ...modalPresentation
};

export default constants;
