import { combineReducers } from 'redux';
import constants from "../../constants";
import update from "immutability-helper";

// TODO REDUCERS Страницы поиска объекта в контексте битрикса

const defaultStateItemObject = {
  load: true,
  item: null,
  search: null,
  save: null
};

// Хранит текущий выбранный объект
const stateItemObject = (state = defaultStateItemObject, action) => {
  switch (action.type) {
    case constants.CONTEXT_BITRIX_SAVE:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

const defaultStateSearchObject = {
  processSend: false
};

// Хранение состояния запроса
const stateSearchObject = (state = defaultStateSearchObject, action) => {
  switch (action.type) {
    case constants.CONTEXT_BITRIX_STATUS:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.CONTEXT_BITRIX_STATUS_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.CONTEXT_BITRIX_STATUS_ERROR:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

export default combineReducers({
  stateItemObject,
  stateSearchObject
});