import { combineReducers } from 'redux';
import constants from "../../../constants";
import update from "immutability-helper";

const modalApplicationsData = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.MODAL_APPLICATIONS_TABLE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.MODAL_APPLICATIONS_TABLE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.MODAL_APPLICATIONS_TABLE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

const defaultModalApplicationsTable = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  loading: true,
  data: []
};

const modalApplicationsTable = (state = defaultModalApplicationsTable, action) => {
  switch (action.type) {
    case constants.MODAL_APPLICATIONS_TABLE:
      return update(state, {
        loading: { $set: true }
      });
    case constants.MODAL_APPLICATIONS_TABLE_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.MODAL_APPLICATIONS_TABLE_CHANGE_ALL:
      return update(state, {
        data: { $set: action.payload.data },
        pagination: { $set: action.payload.pagination },
        loading: { $set: false }
      });
    case constants.MODAL_APPLICATIONS_RESETS_DATA:
      return defaultModalApplicationsTable;
    default:
      return state;
  }
};

const defaultModalApplicationsToggle = {
  modal: false
};

const modalApplicationsToggle = (state = defaultModalApplicationsToggle, action) => {
  switch (action.type) {
    case constants.MODAL_APPLICATIONS_TOGGLE:
      return update(state, {
        modal: { $set: action.payload }
      });
    default:
      return state;
  }
};

const defaultModalApplicationsSave = {
  id_request: [],
  object_id: null
};

const modalApplicationsSave = (state = defaultModalApplicationsSave, action) => {
  switch (action.type) {
    case constants.MODAL_APPLICATIONS_SAVE:
      return update(state, {
        $merge: action.payload
      });
    case constants.MODAL_APPLICATIONS_RESETS_DATA:
      return defaultModalApplicationsSave;
    default:
      return state;
  }
};

const modalApplicationsSaveSand = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.MODAL_APPLICATIONS_TABLE_SAVE_SEND:
      return { processSend: true };
    case constants.MODAL_APPLICATIONS_TABLE_SAVE_SEND_SUCCESS:
      return { processSend: false };
    case constants.MODAL_APPLICATIONS_TABLE_SAVE_SEND_ERROR:
      return { processSend: false };
    default:
      return state;
  }
};

export default combineReducers({
  modalApplicationsSaveSand,
  modalApplicationsData,
  modalApplicationsTable,
  modalApplicationsToggle,
  modalApplicationsSave
});
