export default {
  NEW_OBJECT_SHOW_VALID: 'NEW_OBJECT_SHOW_VALID',

  NEW_OBJECT_SEND: 'NEW_OBJECT_SEND',
  NEW_OBJECT_SEND_SUCCESS: 'NEW_OBJECT_SEND_SUCCESS',
  NEW_OBJECT_SEND_ERROR: 'NEW_OBJECT_SEND_ERROR',

  NEW_OBJECT_LOAD: 'NEW_OBJECT_LOAD',
  NEW_OBJECT_LOAD_SUCCESS: 'NEW_OBJECT_LOAD_SUCCESS',
  NEW_OBJECT_LOAD_ERROR: 'NEW_OBJECT_LOAD_ERROR',

  NEW_OBJECT_LOAD_RESET: 'NEW_OBJECT_LOAD_RESET',

  SAVE_PAGE_OBJECT: 'SAVE_PAGE_OBJECT',
  SAVE_PAGE_OBJECT_DEFAULT: 'SAVE_PAGE_OBJECT_DEFAULT'
}
