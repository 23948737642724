import { combineReducers } from "redux";
import constants from "../../../constants";
import update from "immutability-helper";

const defaultModalToggle = {
  modal: false
};

const modalToggle = (state = defaultModalToggle, action) => {
  switch (action.type) {
    case constants.MODAL_SUCCESS_TOGGLE:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

export default combineReducers({
  modalToggle
});