import imports from "./imports";
import exports from "./exports";
import editSource from "./editSource";
import exportLimit from "./exportlimit";
import exportSetting from "./exportSetting";
// import settings from "./settings";
import editCondition from "./exportSetting/editCondition";
import roles from "./roles";
import rights from "./roles/rights";
import rightsSection from "./roles/rightsSection";
import rightsBox from "./roles/rightsBox";
import listValue from "./listValue";
import handbook from "./handbook";

export default {
  handbook,
  editSource,
  imports,
  exports,
  exportLimit,
  exportSetting,
  // settings,
  editCondition,
  roles,
  rights,
  rightsSection,
  rightsBox,
  listValue
}
