export default {
  OBJECT_PHOTO_FILE_UPLOAD: 'OBJECT_PHOTO_FILE_UPLOAD',
  OBJECT_PHOTO_FILE_UP_ALL: 'OBJECT_PHOTO_FILE_UP_ALL',
  OBJECT_PHOTO_FILE_CHANGE: 'OBJECT_PHOTO_FILE_CHANGE',
  OBJECT_PHOTO_FILE_ORDER: 'OBJECT_PHOTO_FILE_ORDER',
  OBJECT_PHOTO_FILE_UPLOAD_DELETE: 'OBJECT_PHOTO_FILE_UPLOAD_DELETE',

  OBJECT_FILE_MODAL_CHANGE: 'OBJECT_FILE_MODAL_CHANGE',
  OBJECT_FILE_CHANGE: 'OBJECT_FILE_CHANGE',
  OBJECT_FILE_CHANGE_PUBLISHED: 'OBJECT_FILE_CHANGE_PUBLISHED',

  OBJECT_VIDEO_MODAL_TOGGLE: 'OBJECT_VIDEO_MODAL_TOGGLE',

  OBJECT_VIDEO_MODAL_FORM_CHANGE: 'OBJECT_VIDEO_MODAL_FORM_CHANGE',

  OBJECT_VIDEO_SET: 'OBJECT_VIDEO_SET',
  OBJECT_VIDEO_ADD: 'OBJECT_VIDEO_ADD',
  OBJECT_VIDEO_DELETE: 'OBJECT_VIDEO_DELETE',

  OBJECT_SOURCE_RESET_ALL: 'OBJECT_SOURCE_RESET_ALL',

  OBJECT_PHOTO_DELETE: 'OBJECT_PHOTO_DELETE',
  OBJECT_PHOTO_DELETE_SUCCESS: 'OBJECT_PHOTO_DELETE_SUCCESS',
  OBJECT_PHOTO_DELETE_ERROR: 'OBJECT_PHOTO_DELETE_ERROR',
}
