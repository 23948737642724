export default {
  PROPERTY_APPLICATIONS_LOAD: 'PROPERTY_APPLICATIONS_LOAD',
  PROPERTY_APPLICATIONS_LOAD_SUCCESS: 'PROPERTY_APPLICATIONS_LOAD_SUCCESS',
  PROPERTY_APPLICATIONS_LOAD_ERROR: 'PROPERTY_APPLICATIONS_LOAD_ERROR',

  PROPERTY_APPLICATIONS_AUTOCOMPLETE: 'PROPERTY_APPLICATIONS_AUTOCOMPLETE',
  PROPERTY_APPLICATIONS_AUTOCOMPLETE_SUCCESS: 'PROPERTY_APPLICATIONS_AUTOCOMPLETE_SUCCESS',
  PROPERTY_APPLICATIONS_AUTOCOMPLETE_ERROR: 'PROPERTY_APPLICATIONS_AUTOCOMPLETE_ERROR',

  PROPERTY_APPLICATIONS_AUTOCOMPLETE_SOURCE: 'PROPERTY_APPLICATIONS_AUTOCOMPLETE_SOURCE',

  PROPERTY_APPLICATIONS_UPDATE_LVL: 'PROPERTY_APPLICATIONS_UPDATE_LVL',
  PROPERTY_APPLICATIONS_UPDATE_POLE: 'PROPERTY_APPLICATIONS_UPDATE_POLE',

  PROPERTY_APPLICATIONS_LOAD_CHANGE: 'PROPERTY_APPLICATIONS_LOAD_CHANGE',

  PROPERTY_APPLICATIONS_ADD_FORM: 'PROPERTY_APPLICATIONS_ADD_FORM',

  PROPERTY_APPLICATIONS_LOAD_VALIDATE: 'PROPERTY_APPLICATIONS_LOAD_VALIDATE',

  PROPERTY_APPLICATIONS_RESTS_ALL: 'PROPERTY_APPLICATIONS_RESTS_ALL',
}
