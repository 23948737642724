export default {
  MODAL_WORKERS: 'MODAL_WORKERS',

  MODAL_WORKERS_LOAD: 'MODAL_WORKERS_LOAD',
  MODAL_WORKERS_LOAD_SUCCESS: 'MODAL_WORKERS_LOAD_SUCCESS',
  MODAL_WORKERS_LOAD_ERROR: 'MODAL_WORKERS_LOAD_ERROR',

  MODAL_WORKERS_SEND_SELECT: 'MODAL_WORKERS_SEND_SELECT',

  MODAL_WORKERS_CHANGE: 'MODAL_WORKERS_CHANGE',
  MODAL_WORKERS_VALIDATE: 'MODAL_WORKERS_VALIDATE',

  MODAL_WORKERS_SEND: 'MODAL_WORKERS_SEND',
  MODAL_WORKERS_SEND_SUCCESS: 'MODAL_WORKERS_SEND_SUCCESS',
  MODAL_WORKERS_SEND_ERROR: 'MODAL_WORKERS_SEND_ERROR',

  MODAL_WORKERS_RESETS: 'MODAL_WORKERS_RESETS',
}