import constants from "../../../constants";
import { combineReducers } from "redux";
import update from "immutability-helper";

let defaultToggleEditSourceModal = {
  modal: false,
  data: null,
  params: null
};

const toggleEditSourceModal = (state = defaultToggleEditSourceModal, action) => {
  switch (action.type) {
    case constants.EDIT_SOURCE_MODAL_TOGGLE:
      return update(state, {
        modal: { $set: action.payload.modal },
        data: { $set: action.payload.data },
        params: { $set: action.payload.params }
      });
    default:
      return state;
  }
};

let defaultEditModalFormChange = {
  load: false,
  send: false,
  itemPeriodicity: "",
  file_type: "",
  active: "",
  name: "",
  url: "",

  hourly_hours: "",
  daily_day: "",
  weekly_weekNumber: "",
  weekly_weekName: [],

  hourly_end: "never",
  daily_end: "never",
  weekdays_end: "never",
  weekly_end: "never",

  hourly_after_iteration: "",
  weekly_after_iteration: "",
  daily_after_iteration: "",
  weekdays_after_iteration: "",

  hourly_date_dateEnd: "",
  weekly_date_dateEnd: "",
  daily_date_dateEnd: "",
  weekdays_date_dateEnd: "",
};

const editModalFormStatus = (state = defaultEditModalFormChange, action) => {
  switch (action.type) {
    case constants.EDIT_SOURCE_MODAL_FORM_CHANGE:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value },
      });
    case constants.EDIT_SOURCE_MODAL_FORM_UPDATE_END:
      return update(state, {
        send: { $set: false },
      });
    case constants.EDIT_SOURCE_MODAL_FORM_RESETS:
      return defaultEditModalFormChange;
    default:
      return state;
  }
};

let defaultEditModalFormValidate = {
  active: false,
  file_type: false,
  name: false,
  url: false,
  itemPeriodicity: false,

  hourly_hours: false,
  daily_day: false,
  weekly_weekNumber: false,
  weekly_weekName: false,

  hourly_end: false,
  daily_end: false,
  weekdays_end: false,
  weekly_end: false,

  hourly_after_iteration: false,
  weekly_after_iteration: false,
  daily_after_iteration: false,
  weekdays_after_iteration: false,

  hourly_date_dateEnd: false,
  weekly_date_dateEnd: false,
  daily_date_dateEnd: false,
  weekdays_date_dateEnd: false,
};

const editSourceFormValid = (state = defaultEditModalFormValidate, action) => {
  switch (action.type) {
    case constants.EDIT_SOURCE_MODAL_FORM_VALIDATE:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value },
      });
    case constants.EDIT_SOURCE_MODAL_FORM_RESETS:
      return defaultEditModalFormValidate;
    default:
      return state;
  }
};

export default combineReducers({
  toggleEditSourceModal,
  editModalFormStatus,
  editSourceFormValid
});
