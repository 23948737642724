// TODO CONSTANTS Страница лимитов экспорта

export default {
  // Хранение данных
  SOURCE_ITEM_LIMITS: 'SOURCE_ITEM_LIMITS',

  // Отслеживание изменений
  SOURCE_FORM_LIMITS_INIT: 'SOURCE_FORM_LIMITS_INIT',
  SOURCE_FORM_LIMITS_CHANGE: 'SOURCE_FORM_LIMITS_CHANGE',

  // Отправка новых лимитов
  SOURCE_UPDATE_LIMITS: 'SOURCE_UPDATE_LIMITS',
  SOURCE_UPDATE_LIMITS_SUCCESS: 'SOURCE_UPDATE_LIMITS_SUCCESS',
  SOURCE_UPDATE_LIMITS_ERROR: 'SOURCE_UPDATE_LIMITS_ERROR',

  // Сброс параметров на дефолтные
  SOURCE_ITEM_LIMITS_DEFAULT: 'SOURCE_ITEM_LIMITS_DEFAULT'
};
