import constants from "../../../constants";
import { combineReducers } from "redux";
import update from "immutability-helper";

// TODO REDUCERS Страницы значений словаря

const defaultSaveGlobalVars = {
  name_dictionary: null,
  data: null
};

// Храним глобальные данные для страницы
const saveGlobalVars = (state = defaultSaveGlobalVars, action) => {
  switch (action.type) {
    case constants.LIST_VALUE_GLOBAL_SAVE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.LIST_VALUE_DEFAULT_DATA:
      return defaultSaveGlobalVars;
    default:
      return state;
  }
}

const defaultListValueDataTable = {
  processSend: true
};

// Храним свойства для рендера
const listValueDataTable = (state = defaultListValueDataTable, action) => {
  switch (action.type) {
    case constants.LIST_VALUE_DATA_TABLE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.LIST_VALUE_DATA_TABLE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.LIST_VALUE_DATA_TABLE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.LIST_VALUE_DEFAULT_DATA:
      return defaultListValueDataTable;
    default:
      return state;
  }
};

const defaultListValueStateTable = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  data: []
};
// Храним свойства таблицы
const listValueStateTable = (state = defaultListValueStateTable, action) => {
  switch (action.type) {
    case constants.LIST_VALUE_CHANGE_PROPERTY_IN_TABLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.LIST_VALUE_DEFAULT_DATA:
      return defaultListValueStateTable;
    default:
      return state;
  }
};

const defaultListValueStateConfirmDelete = {
  modal: false,
  data: false
};

// Храним свойства модального окна на удаление
const listValueStateConfirmDelete = (state = defaultListValueStateConfirmDelete, action) => {
  switch (action.type) {
    case constants.LIST_VALUE_TOGGLE_CONFIRM_DELETE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultListValueStateConfirmDeleteSend = {
  processSend: false
};

// Отправка на удаление значения
const listValueStateConfirmDeleteSend = (state = defaultListValueStateConfirmDeleteSend, action) => {
  switch (action.type) {
    case constants.LIST_VALUE_DELETE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.LIST_VALUE_DELETE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.LIST_VALUE_DELETE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.LIST_VALUE_DEFAULT_DATA:
      return defaultListValueStateConfirmDeleteSend;
    default:
      return state;
  }
};

const defaultListValueEdit = {
  modal: false,
  data: null
};

// Редактирование/добавление нового значения
const listValueEdit = (state = defaultListValueEdit, action) => {
  switch (action.type) {
    case constants.LIST_VALUE_EDIT_TOGGLE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultListValueEditPole = {
  code: "",
  value: "",
  synonyms: ""
};

// Поля для модального окна редактирование/добавление нового значения
const listValueEditPole = (state = defaultListValueEditPole, action) => {
  switch (action.type) {
    case constants.LIST_VALUE_EDIT_CHANGE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.LIST_VALUE_EDIT_CHANGE_DEFAULT:
      return defaultListValueEditPole;
    default:
      return state;
  }
};

const defaultListValueEditPoleValidate = {

};

// Валидация полей для модального окна редактирование/добавление нового значения
const listValueEditPoleValidate = (state = defaultListValueEditPoleValidate, action) => {
  switch (action.type) {
    case constants.LIST_VALUE_EDIT_VALIDATE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.LIST_VALUE_EDIT_VALIDATE_DEFAULT:
      return defaultListValueEditPoleValidate;
    default:
      return state;
  }
};

const defaultListValueEditOrAddValue = {
  processSend: false
};

// Добавление/Редактирование значения
const listValueEditOrAddValue = (state = defaultListValueEditOrAddValue, action) => {
  switch (action.type) {
    case constants.LIST_VALUE_ADD_PROPERTY:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.LIST_VALUE_ADD_PROPERTY_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.LIST_VALUE_ADD_PROPERTY_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.LIST_VALUE_DEFAULT_DATA:
      return defaultListValueEditOrAddValue;
    default:
      return state;
  }
};

export default combineReducers({
  saveGlobalVars,
  listValueDataTable,
  listValueStateTable,
  listValueStateConfirmDelete,
  listValueStateConfirmDeleteSend,
  listValueEdit,
  listValueEditPole,
  listValueEditPoleValidate,
  listValueEditOrAddValue
});
