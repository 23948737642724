// TODO CONSTANTS Страницы клиентов

export default {
  // Процесс загрузки данных
  CLIENTS_DATA_TABLE: 'CLIENTS_DATA_TABLE',
  CLIENTS_DATA_TABLE_SUCCESS: 'CLIENTS_DATA_TABLE_SUCCESS',
  CLIENTS_DATA_TABLE_ERROR: 'CLIENTS_DATA_TABLE_ERROR',

  // Процесс загрузки типов контакта
  CLIENTS_TYPE_STATUS: 'CLIENTS_TYPE_STATUS',
  CLIENTS_TYPE_STATUS_SUCCESS: 'CLIENTS_TYPE_STATUS_SUCCESS',
  CLIENTS_TYPE_STATUS_ERROR: 'CLIENTS_TYPE_STATUS_ERROR',

  // Обнавляем свойства полей
  CLIENTS_UPDATE_PROPERTY_POLE: 'CLIENTS_PROPERTY_POLE',

  // Сохранение сортировки в таблице клиентов
  CLIENTS_SAVE_SORT_TABLE: 'CLIENTS_SAVE_SORT_TABLE',

  // Изменение свойств таблицы
  CLIENTS_CHANGE_PROPERTY_IN_TABLE: 'CLIENTS_CHANGE_PROPERTY_IN_TABLE',

  // Сброс данных до изначальных
  CLIENTS_DEFAULT: 'CLIENTS_DEFAULT',
}
