// TODO CONSTANTS Страницы поиска объекта в контексте битрикса

export default {
  // Статус запроса
  CONTEXT_BITRIX_STATUS: 'CONTEXT_BITRIX_STATUS',
  CONTEXT_BITRIX_STATUS_SUCCESS: 'CONTEXT_BITRIX_STATUS_SUCCESS',
  CONTEXT_BITRIX_STATUS_ERROR: 'CONTEXT_BITRIX_STATUS_ERROR',

  // Сохранение объекта
  CONTEXT_BITRIX_SAVE: 'CONTEXT_BITRIX_SAVE'
}