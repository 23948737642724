import constants from "../constants";

const middleware = store => next => async action => {
  if (action.type !== 'PROMISE') {
    return next(action);
  }

  const [startAction, failureAction, successAction] = action.payload.actions;
  const { successCallback, errorCallback } = action.payload;

  store.dispatch({ type: startAction });

  const response = await action.payload.response;

  if (typeof response === 'undefined') return;

  const json = await response.json();

  if (response.ok) {
    if (typeof json.error !== 'undefined') {

      if (typeof errorCallback === 'function') {
        errorCallback(store.dispatch, json);
      }

      if (json.errors.code === 5) {
        return store.dispatch({
          type: constants.USER_CHECK_TOKEN,
          payload: null
        });
      }

      return store.dispatch({
        type: failureAction,
        payload: (typeof errorCallback === 'function') ? errorCallback(json) : json,
      });
    }

    if (typeof successCallback === 'function') {
      successCallback(store.dispatch, json);
    }

    return store.dispatch({
      type: successAction,
      payload: json,
    });
  } else if (json) {
    if (typeof errorCallback === 'function') {
      return errorCallback(store.dispatch, json);
    }

    if (json.errors.code === 5) {
      return store.dispatch({
        type: constants.USER_CHECK_TOKEN,
        payload: null
      });
    }
  }

  console.error(json.error || `Unknown error occurred (HTTP ${response.status})!`);
};

export default middleware;
