import { combineReducers } from "redux";
import constants from "../../constants";
import update from "immutability-helper";

const defaultInitDoubleObjects = {
  data: null
}

// Данные модалки
const initDoubleObjects = (state = defaultInitDoubleObjects, action) => {
  switch (action.type) {
    case constants.MODAL_DOUBLE_OBJECTS_INIT: {
      return update(state, {
        data: {$set: action.payload}
      })
    }
    default: {
      return state;
    }
  }
}

export default combineReducers({
  initDoubleObjects
});