
export default {
  PRESENTATION_GET: 'PRESENTATION_GET',
  PRESENTATION_GET_SUCCESS: 'PRESENTATION_GET_SUCCESS',
  PRESENTATION_GET_ERROR: 'PRESENTATION_GET_ERROR',
  PRESENTATION_DEFAULT: 'PRESENTATION_DEFAULT',

  PRESENTATION_REQUEST_DATA: 'PRESENTATION_REQUEST_DATA',
  PRESENTATION_REQUEST_RESET: 'PRESENTATION_REQUEST_RESET',

  PRESENTATION_CREATE: 'PRESENTATION_CREATE',
  PRESENTATION_CREATE_SUCCESS: 'PRESENTATION_CREATE_SUCCESS',
  PRESENTATION_CREATE_ERROR: 'PRESENTATION_CREATE_ERROR'
}
