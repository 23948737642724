// TODO CONSTANTS Модального окна добавление контакта

export default {
  // Состояние модального окна
  NEW_CLIENTS_TOGGLE: 'NEW_CLIENTS_TOGGLE',

  // Состояние модального окна с подтверждением контакта
  NEW_CLIENTS_TOGGLE_BY_DOUBLE: 'NEW_CLIENTS_TOGGLE_BY_DOUBLE',

  // Отслеживаем изменеия в полях
  NEW_CLIENTS_CHANGE: 'NEW_CLIENTS_CHANGE',

  // Отслеживаем валидация
  NEW_CLIENTS_VALIDATE: 'NEW_CLIENTS_VALIDATE',

  // Статус отправки полей
  NEW_CLIENTS_SEND: 'NEW_CLIENTS_SEND',
  NEW_CLIENTS_SEND_SUCCESS: 'NEW_CLIENTS_SEND_SUCCESS',
  NEW_CLIENTS_SEND_ERROR: 'NEW_CLIENTS_SEND_ERROR',

  // Сброс на дефолт данные
  NEW_CLIENTS_DEFAULT: 'NEW_CLIENTS_DEFAULT'
}