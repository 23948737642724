export default {
  MAIN_MENU_ACTION: 'MAIN_MENU_ACTION',

  MAIN_LOAD_FILTERS: 'MAIN_LOAD_FILTERS',
  MAIN_LOAD_FILTERS_SUCCESS: 'MAIN_LOAD_FILTERS_SUCCESS',
  MAIN_LOAD_FILTERS_ERROR: 'MAIN_LOAD_FILTERS_ERROR',

  MAIN_MENU_MODAL_TOGGLE: 'MAIN_MENU_MODAL_TOGGLE',

  MAIN_MENU_DBCLICK: 'MAIN_MENU_DBCLICK',

  MAIN_MENU_DELETE_FILTER: 'MAIN_MENU_DELETE_FILTER',
  MAIN_MENU_DELETE_FILTER_SUCCESS: 'MAIN_MENU_DELETE_FILTER_SUCCESS',
  MAIN_MENU_DELETE_FILTER_ERROR: 'MAIN_MENU_DELETE_FILTER_ERROR'
}
