import { combineReducers } from "redux";
import constants from "../../constants";
import update from "immutability-helper";

/* Хранение состояния отрисовки модального окна */
const modalWorkersModalToggle = (state = { modal: false }, action) => {
  switch (action.type) {
    case constants.MODAL_WORKERS:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

/* Хранение данных после парсинга */
const modalWorkersParseWorkers = (state = {}, action) => {
  switch (action.type) {
    case constants.MODAL_WORKERS_SEND_SELECT:
      return action.payload;
    default:
      return state;
  }
};

/* Хранение данных с полей */
const modalWorkersModalChange = (state = {}, action) => {
  switch (action.type) {
    case constants.MODAL_WORKERS_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.MODAL_WORKERS_RESETS:
      return {};
    default:
      return state;
  }
};

/* Валидация полей */
const modalWorkersModalValidate = (state = {}, action) => {
  switch (action.type) {
    case constants.MODAL_WORKERS_VALIDATE:
      return update(state, {
        $merge: action.payload
      });
    case constants.MODAL_WORKERS_RESETS:
      return {};
    default:
      return state;
  }
};

/* Состояние отправки данных */
const modalWorkersSendModal = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.MODAL_WORKERS_SEND:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.MODAL_WORKERS_SEND_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.MODAL_WORKERS_SEND_ERROR:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    default:
      return state;
  }
};

export default combineReducers({
  modalWorkersModalToggle,
  modalWorkersParseWorkers,
  modalWorkersModalChange,
  modalWorkersModalValidate,
  modalWorkersSendModal
});
