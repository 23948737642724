import { combineReducers } from 'redux';
import constants from "../../../../constants";
import update from "immutability-helper";

const filterApplicationLoad = (state = {}, action) => {
  switch (action.type) {
    case constants.FILTER_APPLICATION_LOAD:
      return state;
    case constants.FILTER_APPLICATION_LOAD_SUCCESS:
      return action.payload;
    case constants.FILTER_APPLICATION_LOAD_ERROR:
      return state;
    default:
      return state;
  }
};

const filterAutoCompleteCombineData = (state = {}, action) => {
  switch (action.type) {
    case constants.FILTER_APPLICATION_AUTO_COMPLETE_COMBINE:
      return state;
    case constants.FILTER_APPLICATION_AUTO_COMPLETE_COMBINE_SUCCESS:
      return action.payload;
    case constants.FILTER_APPLICATION_AUTO_COMPLETE_COMBINE_ERROR:
      return state;
    default:
      return state;
  }
};

const filterAutoCompleteData = (state = {}, action) => {
  switch (action.type) {
    case constants.FILTER_APPLICATION_AUTO_COMPLETE:
      return state;
    case constants.FILTER_APPLICATION_AUTO_COMPLETE_SUCCESS:
      return action.payload;
    case constants.FILTER_APPLICATION_AUTO_COMPLETE_ERROR:
      return state;
    default:
      return state;
  }
};

const filterAutoCompleteDataSource = (state = {}, action) => {
  switch (action.type) {
    case constants.FILTER_APPLICATION_AUTO_COMPLETE_SOURCE:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

const filterApplicationChange = (state = {}, action) => {
  switch (action.type) {
    case constants.FILTER_APPLICATION_CHANGE:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });
    case constants.FILTER_APPLICATION_FILTER_UPDATE:
      return update(state, {
        $merge: action.payload
      });
    case constants.FILTER_APPLICATION_RESETS_ALL:
      return {};
    default:
      return state;
  }
};

const filterApplicationFilterAction = (state = {}, action) => {
  switch (action.type) {
    case constants.FILTER_APPLICATION_FILTER_ACTION:
      return action.payload;
    case constants.FILTER_APPLICATION_RESETS_ALL:
      return {};
    default:
      return state;
  }
};

const defaultFilterApplicationToggleModal = {
  modal: false
};

/* Хранение статуса открытия/закрытия модального окна */
const filterApplicationToggleModal = (state = defaultFilterApplicationToggleModal, action) => {
  switch (action.type) {
    case constants.FILTER_APPLICATION_SAVE_PARAMS_MODAL:
      return update(state, {
        modal: { $set: action.payload }
      });
    default:
      return state;
  }
};

const defaultFilterApplicationChangeInModalParamsFilter = {
  name_filter: ""
};

/* Хранение статуса полей в модальном окне */
const filterApplicationChangeInModalParamsFilter = (state = defaultFilterApplicationChangeInModalParamsFilter, action) => {
  switch (action.type) {
    case constants.FILTER_APPLICATION_SAVE_PARAMS_MODAL_CHANGE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.FILTER_APPLICATION_SAVE_PARAMS_MODAL_RESETS_ALL:
      return defaultFilterApplicationChangeInModalParamsFilter;
    default:
      return state;
  }
};

const defaultFilterApplicationValidInModalParamsFilter = {
  name_filter: false
};

/* Валидация полей в модальном окне */
const filterApplicationValidInModalParamsFilter = (state = defaultFilterApplicationValidInModalParamsFilter, action) => {
  switch (action.type) {
    case constants.FILTER_APPLICATION_SAVE_PARAMS_MODAL_VALIDATE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.FILTER_APPLICATION_SAVE_PARAMS_MODAL_RESETS_ALL:
      return defaultFilterApplicationValidInModalParamsFilter;
    default:
      return state;
  }
};

/* Хранение статуса отправки фильтра в модальном окне */
const filterApplicationSaveParamsFilter = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.FILTER_APPLICATION_SAVE_PARAMS_FILTER:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.FILTER_APPLICATION_SAVE_PARAMS_FILTER_SUCCESS:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.FILTER_APPLICATION_SAVE_PARAMS_FILTER_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

export default combineReducers({
  filterApplicationLoad,
  filterAutoCompleteCombineData,
  filterAutoCompleteData,
  filterAutoCompleteDataSource,
  filterApplicationChange,
  filterApplicationFilterAction,
  filterApplicationToggleModal,
  filterApplicationValidInModalParamsFilter,
  filterApplicationChangeInModalParamsFilter,
  filterApplicationSaveParamsFilter
});
