import constants from "../../../../constants";
import update from "immutability-helper";
import { combineReducers } from "redux";

// TODO REDUCERS Свойств роли (разделы)

const defaultPropertyItemRoleSection = {
  sectionData: []
};

// Храним данные для отображения в таблице
const propertyItemRoleSection = (state = defaultPropertyItemRoleSection, action) => {
  switch (action.type) {
    case constants.PROPERTY_ROLE_SECTION_INIT:
      return update(state, {
        sectionData: {
          $set: action.payload
        }
      });
    case constants.PROPERTY_ROLE_SECTION_EDIT:
      return update(state, {
        sectionData: {
          [action.payload.itemIndex]: {
            [action.payload.itemChange]: {
              $set: action.payload.itemChangeValue
            }
          }
        }
      });
    case constants.PROPERTY_ROLE_SECTION_DEFAULT:
      return defaultPropertyItemRoleSection;
    default:
      return state;
  }
};

export default combineReducers({
  propertyItemRoleSection,
});