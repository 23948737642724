import { combineReducers } from 'redux';
import favouritesApplications from "./favouritesApplications";
import newApplications from "./newApplications";
import propertyApplications from "./newApplications/propertyApplications";
import searchApplications from "./searchApplications";
import modalApplications from "./modalApplications";
import searchApplicationsInObject from "./searchApplicationsInObject";

export default combineReducers({
  favouritesApplications,
  modalApplications,
  newApplications,
  propertyApplications,
  searchApplications,
  searchApplicationsInObject,
});
