import map from "./map";
import tables from "./tables";

export default {

  SEARCH_OBJECT_DATA: 'SEARCH_OBJECT_DATA',
  SEARCH_OBJECT_DATA_SUCCESS: 'SEARCH_OBJECT_DATA_SUCCESS',
  SEARCH_OBJECT_DATA_ERROR: 'SEARCH_OBJECT_DATA_ERROR',

  SEARCH_OBJECT_CHANGE_PROPERTY_IN_TABLE: 'SEARCH_OBJECT_CHANGE_PROPERTY_IN_TABLE',

  SEARCH_OBJECT_DEFAULT: 'SEARCH_OBJECT_DEFAULT',

  SEARCH_OBJECT_LOAD_ALL_FILTERS: 'SEARCH_OBJECT_LOAD_ALL_FILTERS',
  SEARCH_OBJECT_LOAD_ALL_FILTERS_SUCCESS: 'SEARCH_OBJECT_LOAD_ALL_FILTERS_SUCCESS',
  SEARCH_OBJECT_LOAD_ALL_FILTERS_ERROR: 'SEARCH_OBJECT_LOAD_ALL_FILTERS_ERROR',

  SEARCH_OBJECT_ADD_ALL: 'SEARCH_OBJECT_ADD_ALL',
  SEARCH_OBJECT_UPDATE_POLE: 'SEARCH_OBJECT_UPDATE_POLE',

  SEARCH_OBJECT_CHANGE_FILTER: 'SEARCH_OBJECT_CHANGE_FILTER',
  SEARCH_OBJECT_CHANGE_SET_FILTER: 'SEARCH_OBJECT_CHANGE_SET_FILTER',
  SEARCH_OBJECT_CHANGE_FILTER_ALL: 'SEARCH_OBJECT_CHANGE_FILTER_ALL',
  SEARCH_OBJECT_FILTER_RESET: 'SEARCH_OBJECT_FILTER_RESET',

  SEARCH_OBJECT_UPDATE_FILTER_ACTION: 'SEARCH_OBJECT_UPDATE_FILTER_ACTION',

  SEARCH_OBJECT_FILTER_CHANGE: "SEARCH_OBJECT_FILTER_CHANGE",

  SEARCH_OBJECT_AUTOCOMPLETE: 'SEARCH_OBJECT_AUTOCOMPLETE',
  SEARCH_OBJECT_AUTOCOMPLETE_SUCCESS: 'SEARCH_OBJECT_AUTOCOMPLETE_SUCCESS',
  SEARCH_OBJECT_AUTOCOMPLETE_ERROR: 'SEARCH_OBJECT_AUTOCOMPLETE_ERROR',

  SEARCH_OBJECT_AUTOCOMPLETE_UPDATE: 'SEARCH_OBJECT_AUTOCOMPLETE_UPDATE',

  SEARCH_OBJECT_LOAD_INFORMATION: 'SEARCH_OBJECT_LOAD_INFORMATION',
  SEARCH_OBJECT_LOAD_INFORMATION_SUCCESS: 'SEARCH_OBJECT_LOAD_INFORMATION_SUCCESS',
  SEARCH_OBJECT_LOAD_INFORMATION_ERROR: 'SEARCH_OBJECT_LOAD_INFORMATION_ERROR',

  SEARCH_OBJECT_SAVE_MAP: 'SEARCH_OBJECT_SAVE_MAP',

  LOAD_FILTER_OBJECT: 'LOAD_FILTER_OBJECT',
  LOAD_FILTER_OBJECT_SUCCESS: 'LOAD_FILTER_OBJECT_SUCCESS',
  LOAD_FILTER_OBJECT_ERROR: 'LOAD_FILTER_OBJECT_ERROR',

  OBJECT_FILTER_CHANGE_GROUP: 'OBJECT_FILTER_CHANGE_GROUP',

  OBJECT_SAVE_MODAL_TOGGLE: 'OBJECT_SAVE_MODAL_TOGGLE',

  OBJECT_SAVE_FILTER: 'OBJECT_SAVE_FILTER',
  OBJECT_SAVE_FILTER_RESETS: 'OBJECT_SAVE_FILTER_RESETS',

  OBJECT_SAVE_FILTER_SEND: 'OBJECT_SAVE_FILTER_SEND',
  OBJECT_SAVE_FILTER_SEND_SUCCESS: 'OBJECT_SAVE_FILTER_SEND_SUCCESS',
  OBJECT_SAVE_FILTER_SEND_ERROR: 'OBJECT_SAVE_FILTER_SEND_ERROR',

  OBJECT_SAVE_FILTER_VALID: 'OBJECT_SAVE_FILTER_VALID',

  OBJECT_SAVE_PAGE: 'OBJECT_SAVE_PAGE',

  ...map,
  ...tables
};
