import { combineReducers } from 'redux';
import form from "./login";
import main from "./main";
import propertyOffice from "./propertyOffice";
import clients from "./clients";
import object from "./object";
import applications from "./applications";
import source from "./source";
import modalWorkers from "./modalWorkers";
import modalDeleteBookmark from "./modalDeleteBookmark";
import modalRedirectToClients from "./modalRedirectToClients";
import modalDoubleObjects from "./modalDoubleObjects";
import newClients from "./newClients";
import common from "./common";
import bitrixSearchObject from "./bitrixSearchObject";
import publicReducer from "./public";
import modalPresentation from "./modalPresentation";

export default combineReducers({
  login: form,
  main,
  propertyOffice,
  clients,
  object,
  applications,
  ...source,
  modalWorkers,
  modalDeleteBookmark,
  modalRedirectToClients,
  modalDoubleObjects,
  newClients,
  common,
  bitrixSearchObject,
  public: publicReducer,
  modalPresentation: modalPresentation
});
