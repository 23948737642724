export default {
  MODAL_APPLICATIONS_TABLE: 'MODAL_APPLICATIONS_TABLE',
  MODAL_APPLICATIONS_TABLE_SUCCESS: 'MODAL_APPLICATIONS_TABLE_SUCCESS',
  MODAL_APPLICATIONS_TABLE_ERROR: 'MODAL_APPLICATIONS_TABLE_ERROR',

  MODAL_APPLICATIONS_RESETS_DATA: 'MODAL_APPLICATIONS_RESETS_DATA',
  MODAL_APPLICATIONS_TOGGLE: 'MODAL_APPLICATIONS_TOGGLE',
  MODAL_APPLICATIONS_SAVE: 'MODAL_APPLICATIONS_SAVE',

  MODAL_APPLICATIONS_TABLE_SAVE_SEND: 'MODAL_APPLICATIONS_TABLE_SAVE_SEND',
  MODAL_APPLICATIONS_TABLE_SAVE_SEND_SUCCESS: 'MODAL_APPLICATIONS_TABLE_SAVE_SEND_SUCCESS',
  MODAL_APPLICATIONS_TABLE_SAVE_SEND_ERROR: 'MODAL_APPLICATIONS_TABLE_SAVE_SEND_ERROR',

  MODAL_APPLICATIONS_TABLE_CHANGE: 'MODAL_APPLICATIONS_TABLE_CHANGE',
  MODAL_APPLICATIONS_TABLE_CHANGE_ALL: 'MODAL_APPLICATIONS_TABLE_CHANGE_ALL',
}
