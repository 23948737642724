import constants from "../../../constants";
import update from "immutability-helper";
import { combineReducers } from "redux";

const modalObjectsData = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.MODAL_OBJECTS_TABLE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.MODAL_OBJECTS_TABLE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.MODAL_OBJECTS_TABLE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

const defaultModalObjectsTable = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  loading: true,
  data: []
};

const modalObjectsTable = (state = defaultModalObjectsTable, action) => {
  switch (action.type) {
    case constants.MODAL_OBJECTS_TABLE:
      return update(state, {
        loading: { $set: true }
      });
    case constants.MODAL_OBJECTS_TABLE_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.MODAL_OBJECTS_TABLE_CHANGE_ALL:
      return update(state, {
        data: { $set: action.payload.data || [] },
        pagination: { $set: action.payload.pagination || false },
        loading: { $set: false }
      });
    case constants.MODAL_OBJECTS_RESETS_DATA:
      return defaultModalObjectsTable;
    default:
      return state;
  }
};

const defaultModalObjectsToggle = {
  modal: false
};

const modalObjectsToggle = (state = defaultModalObjectsToggle, action) => {
  switch (action.type) {
    case constants.MODAL_OBJECTS_TOGGLE:
      return update(state, {
        modal: { $set: action.payload }
      });
    default:
      return state;
  }
};

const defaultModalObjectsSave = {
  id_objects: [],
  id_request: null
};

const modalObjectsSave = (state = defaultModalObjectsSave, action) => {
  switch (action.type) {
    case constants.MODAL_OBJECTS_SAVE:
      return update(state, {
        $merge: action.payload
      });
    case constants.MODAL_OBJECTS_RESETS_DATA:
      return defaultModalObjectsSave;
    default:
      return state;
  }
};

const modalObjectsSaveSand = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.MODAL_OBJECTS_TABLE_SAVE_SEND:
      return { processSend: true };
    case constants.MODAL_OBJECTS_TABLE_SAVE_SEND_SUCCESS:
      return { processSend: false };
    case constants.MODAL_OBJECTS_TABLE_SAVE_SEND_ERROR:
      return { processSend: false };
    default:
      return state;
  }
};

export default combineReducers({
  modalObjectsSaveSand,
  modalObjectsData,
  modalObjectsTable,
  modalObjectsToggle,
  modalObjectsSave
});
