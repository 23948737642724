import { combineReducers } from 'redux';
import constants from "../../../../constants";
import update from "immutability-helper";

const defaultDataTableDoubleObject = {
  processSend: true
}

const dataTableDoubleObject = (state = defaultDataTableDoubleObject, action) => {
  switch (action.type) {
    case constants.DOUBLE_OBJECT:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.DOUBLE_OBJECT_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.DOUBLE_OBJECT_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
}

const defaultTableDoubleObject = {
  data: []
}

const tableDoubleObject = (state = defaultTableDoubleObject, action) => {
  switch (action.type) {
    case constants.DOUBLE_OBJECT_UPDATE_TABLE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.DOUBLE_OBJECT_DEFAULT:
      return defaultTableDoubleObject;
    default:
      return state;
  }
}

export default combineReducers({
  dataTableDoubleObject,
  tableDoubleObject
});