import { combineReducers } from "redux";
import constants from "../../../../constants";
import update from "immutability-helper";

const tableShowUserInfo = (state = [], action) => {
  switch (action.type) {
    case constants.APPLICATIONS_TABLE_OPEN_USER_SHOW:
      return update(state, {
        $push: action.payload
      });
    case constants.APPLICATIONS_DATA_RESETS:
      return [];
    default:
      return state;
  }
};

const tableShowUserInfoStatus = (state = {}, action) => {
  switch (action.type) {
    case constants.APPLICATIONS_TABLE_OPEN_USER:
      return state;
    case constants.APPLICATIONS_TABLE_OPEN_USER_SUCCESS:
      return update(state, {
        $merge: action.payload
      });
    case constants.APPLICATIONS_TABLE_OPEN_USER_ERROR:
      return state;
    case constants.APPLICATIONS_DATA_RESETS:
      return {};
    default:
      return state;
  }
};

const dataTableSearch = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.APPLICATIONS_TABLE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.APPLICATIONS_TABLE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.APPLICATIONS_TABLE_ERROR:
      return update(state, {
        error: { $set: true },
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

const defaultTableSortingOnAllPages = {
  sortItem: "",
  columnSort: "",
};

const tableSortingOnPageSearch = (state = defaultTableSortingOnAllPages, action) => {
  switch (action.type) {
    case constants.APPLICATIONS_TABLE_SEARCH:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const tableSortingOnPageMy = (state = defaultTableSortingOnAllPages, action) => {
  switch (action.type) {
    case constants.APPLICATIONS_TABLE_SEARCH_MY:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const tableSortingOnPageFree = (state = defaultTableSortingOnAllPages, action) => {
  switch (action.type) {
    case constants.APPLICATIONS_TABLE_SEARCH_FREE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultTableChange = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  loading: true,
  data: []
};

/* Хранение данных и сортировки для таблицы  */
const tableChange = (state = defaultTableChange, action) => {
  switch (action.type) {
    case constants.APPLICATIONS_TABLE:
      return update(state, {
        loading: { $set: true }
      });
    case constants.APPLICATIONS_TABLE_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.APPLICATIONS_TABLE_CHANGE_ALL:
      return update(state, {
        data: { $set: action.payload.data },
        pagination: { $set: action.payload.pagination },
        loading: { $set: false }
      });
    case constants.APPLICATIONS_DATA_RESETS:
      return defaultTableChange;
    default:
      return state;
  }
};

export default combineReducers({
  tableSortingOnPageSearch,
  tableSortingOnPageFree,
  tableSortingOnPageMy,
  dataTableSearch,
  tableChange,
  tableShowUserInfo,
  tableShowUserInfoStatus,
});
