import constants from "../../../../constants";
import update from "immutability-helper";
import { combineReducers } from "redux";

// TODO REDUCERS Свойств роли (поля)

const defaultPropertyItemRoleBox = {
  boxData: []
};

// Храним данные для отображения в таблице
const propertyItemRoleBox = (state = defaultPropertyItemRoleBox, action) => {
  switch (action.type) {
    case constants.PROPERTY_ROLE_BOX_INIT:
      return update(state, {
        boxData: {
          $set: action.payload
        }
      });
    case constants.PROPERTY_ROLE_BOX_EDIT:
      return update(state, {
        boxData: {
          [action.payload.itemIndex]: {
            [action.payload.itemChange]: {
              $set: action.payload.itemChangeValue
            }
          }
        }
      });
    case constants.PROPERTY_ROLE_BOX_DEFAULT:
      return defaultPropertyItemRoleBox;
    default:
      return state;
  }
};

export default combineReducers({
  propertyItemRoleBox,
});