import constants from "../../../constants";
import { combineReducers } from "redux";
import update from "immutability-helper";

// TODO REDUCERS Страницы импорта

const defaultDataTableImports = {
  processSend: true
};

// Хранение данных для таблицы импорта
const dataTableImports = (state = defaultDataTableImports, action) => {
  switch (action.type) {
    case constants.IMPORTS_DATA_TABLE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.IMPORTS_DATA_TABLE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.IMPORTS_DATA_TABLE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.IMPORTS_DEFAULT:
      return defaultDataTableImports;
    default:
      return state;
  }
};

let defaultPropertyTableImports = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  data: []
};

// Хранение свойств таблицы
const propertyTableImports = (state = defaultPropertyTableImports, action) => {
  switch (action.type) {
    case constants.IMPORTS_CHANGE_PROPERTY_IN_TABLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.IMPORTS_DEFAULT:
      return defaultPropertyTableImports;
    default:
      return state;
  }
};

const sendStatusModalForm = (state = false, action) => {
  switch (action.type) {
    case constants.IMPORTS_UPDATE_SOURCE:
      return false;
    case constants.IMPORTS_UPDATE_SOURCE_SUCCESS:
      return action.payload;
    case constants.IMPORTS_UPDATE_SOURCE_ERROR:
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  dataTableImports,
  propertyTableImports,
  sendStatusModalForm,
});
