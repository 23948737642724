export default {
  APPLICATIONS_TABLE: 'APPLICATIONS_TABLE',
  APPLICATIONS_TABLE_SUCCESS: 'APPLICATIONS_TABLE_SUCCESS',
  APPLICATIONS_TABLE_ERROR: 'APPLICATIONS_TABLE_ERROR',

  APPLICATIONS_TABLE_OPEN_USER: 'APPLICATIONS_TABLE_OPEN_USER',
  APPLICATIONS_TABLE_OPEN_USER_SUCCESS: 'APPLICATIONS_TABLE_OPEN_USER_SUCCESS',
  APPLICATIONS_TABLE_OPEN_USER_ERROR: 'APPLICATIONS_TABLE_OPEN_USER_ERROR',

  APPLICATIONS_TABLE_OPEN_USER_SHOW: 'APPLICATIONS_TABLE_OPEN_USER_SHOW',

  APPLICATIONS_TABLE_SEARCH: 'APPLICATIONS_TABLE_SEARCH',
  APPLICATIONS_TABLE_SEARCH_MY: 'APPLICATIONS_TABLE_SEARCH_MY',
  APPLICATIONS_TABLE_SEARCH_FREE: 'APPLICATIONS_TABLE_SEARCH_FREE',

  APPLICATIONS_TABLE_CHANGE: 'APPLICATIONS_TABLE_CHANGE',

  APPLICATIONS_TABLE_CHANGE_ALL: 'APPLICATIONS_TABLE_CHANGE_ALL',
}
