import { combineReducers } from 'redux';
import constants from "../../../constants";
import update from "immutability-helper";

const newApplicationsSend = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.NEW_APPLICATIONS_SEND:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.NEW_APPLICATIONS_SEND_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.NEW_APPLICATIONS_SEND_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.PROPERTY_APPLICATIONS_RESTS_ALL:
      return { processSend: false };
    default:
      return state;
  }
};

const loadApplications = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.EDIT_APPLICATIONS_LOAD:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.EDIT_APPLICATIONS_LOAD_SUCCESS:
      return update(state, {
        $set: {
          processSend: false,
          ...action.payload
        }
      });
    case constants.EDIT_APPLICATIONS_LOAD_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.PROPERTY_APPLICATIONS_RESTS_ALL:
      return {};
    default:
      return state;
  }
};

const defaultSaveStatePageApplication = {
  activeTab: null,
  form: {
    data: null,
    autocomplete: null
  },
  searchFilter: {
    map: {
      mode: null,
      polygon: null,
      bounds: {
        centerMap: null,
        mapZoom: null,
        northWest: null,
        southEast: null
      }
    },
    bookmark: null,
    autocomplete: {
      searchObjectAutoCompleteSearchParse: null
    },
    state: null,
    action: null
  }
}

const saveStatePageApplication = (state = defaultSaveStatePageApplication, action) => {
  switch (action.type) {
    case constants.SAVE_PAGE_APPLICATION:
      return update(state, {
        $merge: action.payload
      });
    case constants.SAVE_PAGE_APPLICATION_DEFAULT:
      return defaultSaveStatePageApplication;
    default:
      return state;
  }
}

export default combineReducers({
  loadApplications,
  newApplicationsSend,
  saveStatePageApplication
});
