import { combineReducers } from "redux";
import constants from '../../../../constants';
import update from "immutability-helper";

// TODO REDUCERS Модального окна по генерации фильтров

const defaultChangeModal = {
  is_update: false,
  is_open: false,
  itemArrayIndex: null,
  data: null,
  main: [],
  other: []
};

// Хранение исходных данных
const changeModal = (state = defaultChangeModal, action) => {
  switch (action.type) {
    case constants.SHOW_FILTER_MODAL_TOGGLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.SHOW_FILTER_MODAL_DEFAULT:
      return defaultChangeModal;
    default:
      return state;
  }
};

const defaultChangeSettingCard = {
  lanes: [
    {
      title: 'Доступные фильтры',
      itemDrop: "other",
      cards: []
    },
    {
      title: 'Фильтры для отображения',
      itemDrop: "main",
      cards: []
    }
  ]
};

// Хранение данных для вывода и для элементов поиска
const changeSettingCard = (state = defaultChangeSettingCard, action) => {
  switch (action.type) {
    case constants.SHOW_FILTER_MODAL_SEARCH:
      return update(state, {
        lanes: {
          0: {
            cards: { $set: action.payload }
          }
        }
      });
    case constants.SHOW_FILTER_MODAL_INIT_CARD:
      return update(state, {
        lanes: {
          0: {
            cards: { $set: action.payload.lanes[0].cards }
          },
          1: {
            cards: { $set: action.payload.lanes[1].cards }
          }
        }
      });
    case constants.SHOW_FILTER_MODAL_CHANGE:
      const {
        deleteIndexColumn, deleteIndexCard,
        addIndexColumn, addedDataInColumn
      } = action.payload;

      return update(state, {
        lanes: {
          [addIndexColumn]: {
            cards: {
              $push: [addedDataInColumn]
            }
          },
          [deleteIndexColumn]: {
            cards: {
              $splice: [
                [deleteIndexCard, 1]
              ]
            }
          }
        }
      });
    case constants.SHOW_FILTER_MODAL_DEFAULT:
      return defaultChangeSettingCard;
    default:
      return state;
  }
};

const defaultSearchSettingCard = {
  searchInput: "",
  saveOther: [],
};

// Хранение элементов поиска
const searchSettingCard = (state = defaultSearchSettingCard, action) => {
  switch (action.type) {
    case constants.SHOW_FILTER_MODAL_SEARCH_CHANGE:
      return update(state, {
        searchInput: { $set: action.payload }
      });
    case constants.SHOW_FILTER_MODAL_SAVE_OTHER:
      return update(state, {
        saveOther: { $set: action.payload }
      });
    case constants.SHOW_FILTER_MODAL_SAVE_OTHER_CHANGE:
      if (action.payload.hasOwnProperty("addedDataInColumn")) {
        return update(state, {
          saveOther: {
            $push: [action.payload["addedDataInColumn"]]
          }
        });
      }

      if (action.payload.hasOwnProperty("deleteIndexCard")) {
        return update(state, {
          saveOther: {
            $splice: [
              [action.payload["deleteIndexCard"], 1]
            ]
          }
        });
      }

      return state;
    case constants.SHOW_FILTER_MODAL_DEFAULT:
      return defaultSearchSettingCard;
    default:
      return state;
  }
};

export default combineReducers({
  changeModal,
  changeSettingCard,
  searchSettingCard,
});
