import rights from "./rights";
import rightsSection from "./rightsSection";
import rightsBox from "./rightsBox";

// TODO CONSTANTS Страницы ролей

export default {
  // Процесс загрузки данных
  ROLES_DATA: 'ROLES_DATA',
  ROLES_DATA_SUCCESS: 'ROLES_DATA_SUCCESS',
  ROLES_DATA_ERROR: 'ROLES_DATA_ERROR',

  // Сохранение полей для селектов
  ROLES_SAVE_PROPERTY_POLES: 'ROLES_SAVE_PROPERTY_POLES',

  // Обновление свойст таблицы
  ROLES_CHANGE_PROPERTY_IN_TABLE: 'ROLES_CHANGE_PROPERTY_IN_TABLE',

  // Сохранение сортировки в таблице
  ROLES_SAVE_SORT_PROPERTY_IN_TABLE: 'ROLES_SAVE_SORT_PROPERTY_IN_TABLE',

  // Статус модального окна редактирования/добавления
  ROLES_EDIT_TOGGLE_MODAL: 'ROLES_EDIT_TOGGLE_MODAL',
  // Отслеживание изменений модального окна редактирования/добавления
  ROLES_EDIT_TOGGLE_CHANGE: 'ROLES_EDIT_TOGGLE_CHANGE',
  // Валидирование модального окна редактирования/добавления
  ROLES_EDIT_FORM_VALIDATE: 'ROLES_EDIT_FORM_VALIDATE',

  // Отправка новой роли или редактирование старой, статус
  ROLES_ADD_OR_EDIT_ROLE: 'ROLES_ADD_OR_EDIT_ROLE',
  ROLES_ADD_OR_EDIT_ROLE_SUCCESS: 'ROLES_ADD_OR_EDIT_ROLE_SUCCESS',
  ROLES_ADD_OR_EDIT_ROLE_ERROR: 'ROLES_ADD_OR_EDIT_ROLE_ERROR',

  // Статус модального окна удаление роли
  ROLES_CONFIRM_DELETE_TOGGLE_MODAL: 'ROLES_CONFIRM_DELETE_TOGGLE_MODAL',

  // Статус удаления роли
  ROLES_DROP_STATUS: 'ROLES_DROP_STATUS',
  ROLES_DROP_STATUS_SUCCESS: 'ROLES_DROP_STATUS_SUCCESS',
  ROLES_DROP_STATUS_ERROR: 'ROLES_DROP_STATUS_ERROR',

  // Сброс данных не дефолтные
  ROLES_DEFAULT: 'ROLES_DEFAULT',

  ...rights,
  ...rightsSection,
  ...rightsBox,
}
