import { combineReducers } from 'redux';
import constants from "../../../../constants";
import update from "immutability-helper";

const defaultDataTablePlacementInAdvertising = {
  processSend: true
}

const dataTablePlacementInAdvertising = (state = defaultDataTablePlacementInAdvertising, action) => {
  switch (action.type) {
    case constants.PLACEMENT_IN_ADVERTISING_GET:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.PLACEMENT_IN_ADVERTISING_GET_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.PLACEMENT_IN_ADVERTISING_GET_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
}

const defaultTablePlacementInAdvertising = {
  data: []
}

const tablePlacementInAdvertising = (state = defaultTablePlacementInAdvertising, action) => {
  switch (action.type) {
    case constants.PLACEMENT_IN_ADVERTISING_TABLE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.PLACEMENT_IN_ADVERTISING_DEFAULT:
      return defaultTablePlacementInAdvertising;
    default:
      return state;
  }
}

export default combineReducers({
  dataTablePlacementInAdvertising,
  tablePlacementInAdvertising
});