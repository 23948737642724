import constants from '../../../constants';
import { combineReducers } from "redux";
import update from "immutability-helper";

// TODO REDUCERS Страница настроек экспорта

const defaultSettingsSource = {
  title: "",
  data: null,
  other: [],
  main: []
};

// Хранение данных
const settingsSource = (state = defaultSettingsSource, action) => {
  switch (action.type) {
    case constants.SOURCE_ITEM_SETTINGS:
      return update(state, {
        title: { $set: action.payload.title },
        data: { $set: action.payload.data },
        other: { $set: action.payload.other },
        main: { $set: action.payload.main },
      });
    case constants.SOURCE_ITEM_SETTINGS_DEFAULT:
      return defaultSettingsSource;
    default:
      return state;
  }
};

const defaultRenderFiltersSource = {
  poleGenerate: [],
  renderPole: []
};

// Хранение полей для отображения
const renderFiltersSource = (state = defaultRenderFiltersSource, action) => {
  switch (action.type) {
    case constants.SOURCE_ITEM_SETTINGS_UPDATE_RENDER_POLE:
      return update(state, {
        poleGenerate: { $set: action.payload.poleGenerate },
        renderPole: { $set: action.payload.renderPole }
      });
    case constants.SOURCE_ITEM_SETTINGS_ADD_LINE:
      return update(state, {
        renderPole: { $push: [action.payload.pole] }
      });
    case constants.SOURCE_ITEM_SETTINGS_UPDATE_POLE_DATA:
      return update(state, {
        renderPole: {
          [action.payload.itemArrayIndex]: {
            [action.payload.itemPoleIndex]: {
              array: { $set: action.payload.data }
            }
          }
        }
      });
    case constants.SOURCE_ITEM_SETTINGS_DELETE_LINE:
      return update(state, {
        renderPole: {
          $splice: [
            [action.payload.itemArrayIndex, 1]
          ]
        }
      });
    case constants.SOURCE_ITEM_SETTINGS_DEFAULT:
      return defaultRenderFiltersSource;
    default:
      return state;
  }
};

const defaultDataFiltersSource = {
  objectGenerate: null,
  dataPole: []
};

// Храним данные с полей
const dataFiltersSource = (state = defaultDataFiltersSource, action) => {
  switch (action.type) {
    case constants.SOURCE_ITEM_SETTINGS_INIT_POLE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.SOURCE_ITEM_SETTINGS_UPDATE_POLE:
      return update(state, {
        dataPole: {
          [action.payload.itemArrayIndex]: {
            [action.payload.itemPoleIndex]: {
              $merge: action.payload.value
            }
          }
        }
      });
    case constants.SOURCE_ITEM_SETTINGS_ADD_LINE:
      return update(state, {
        dataPole: { $push: [action.payload.dataInPole] }
      });
    case constants.SOURCE_ITEM_SETTINGS_DELETE_LINE:
      return update(state, {
        dataPole: {
          $splice: [
            [action.payload.itemArrayIndex, 1]
          ]
        }
      });
    case constants.SOURCE_ITEM_SETTINGS_DEFAULT:
      return defaultDataFiltersSource;
    default:
      return state;
  }
};

const defaultDataSourceAtoComplete = {
  processSend: false
};

// Данные автокомплита
const dataSourceAtoComplete = (state = defaultDataSourceAtoComplete, action) => {
  switch (action.type) {
    case constants.SOURCE_ITEM_SETTINGS_AUTOCOMPLETE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.SOURCE_ITEM_SETTINGS_AUTOCOMPLETE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.SOURCE_ITEM_SETTINGS_AUTOCOMPLETE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.SOURCE_ITEM_SETTINGS_DEFAULT:
      return defaultDataSourceAtoComplete;
    default:
      return state;
  }
};

// Храним отсортированные данные
const saveAutoComplete = (state = {}, action) => {
  switch (action.type) {
    case constants.SOURCE_ITEM_SETTINGS_AUTOCOMPLETE_SAVE:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

const defaultDataGeolocation = {
  processSend: false
};

// Хранение данных геолокации
const dataGeolocation = (state = defaultDataGeolocation, action) => {
  switch (action.type) {
    case constants.SOURCE_ITEM_SETTINGS_GEO:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.SOURCE_ITEM_SETTINGS_GEO_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.SOURCE_ITEM_SETTINGS_GEO_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

export default combineReducers({
  settingsSource,
  renderFiltersSource,
  dataFiltersSource,
  dataSourceAtoComplete,
  saveAutoComplete,
  dataGeolocation
});
