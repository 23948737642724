import { combineReducers } from "redux";
import favouritesObjects from "./favouritesObjects";
import search from "./search";
import newObject from "./newObject";
import objectProperty from "./newObject/objectProperty";
import objectPhoto from "./newObject/objectPhoto";
import showObject from "./showObject";
import modalObject from "./modalObject";
import commercial from "./newObject/commercial";
import doubleObject from "./newObject/doubleObject";
import placementInAdvertising from "./newObject/placementInAdvertising";

export default combineReducers({
  favouritesObjects,
  search,
  newObject,
  objectProperty,
  objectPhoto,
  showObject,
  modalObject,
  commercial,
  doubleObject,
  placementInAdvertising,
});
