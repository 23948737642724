import { combineReducers } from "redux";
import constants from "../../../../constants";
import update from "immutability-helper";

/* Храним (id) объектов */
const saveObjectsId = (state = [], action) => {
  switch (action.type) {
    case constants.MAP_SAVE_OBJECTS_ID:
      return update(state, {
        $push: action.payload
      });
    case constants.MAP_SAVE_OBJECTS_ID_NEW:
      return action.payload;
    case constants.MAP_SAVE_OBJECTS_ID_RESETS:
      return [];
    case constants.MAP_RESETS_ALL:
      return [];
    default:
      return state;
  }
};

/* Храним координаты объектов с данными */
const savePointsMap = (state = null, action) => {
  switch (action.type) {
    case constants.OBJECT_MAP_SAVE_POINTS:
      return action.payload;
    case constants.MAP_RESETS_ALL:
      return null;
    default:
      return state;
  }
};

const defaultLoadPointsMap = {
  load: false,
  points: [],
  id_objects: []
};

/* Храним объекты из произвольной области */
const addPoints = (state = defaultLoadPointsMap, action) => {
  switch (action.type) {
    case constants.OBJECT_MAP_ADD_MAP_ITEM_AND_DRAW_CHANGE:
      return update(state, {
        points: { $set: action.payload }
      });
    case constants.MAP_SAVE_OBJECTS_GROUP:
      return update(state, {
        $merge: action.payload
      });
    case constants.MAP_RESETS_ALL:
      return defaultLoadPointsMap;
    default:
      return state;
  }
};

const defaultItemObjectPopup = {
  objectsArray: [],
  isOpen: false
};

/* Храним данные для отображения детальной информации объекта на карте */
const itemObjectPopup = (state = defaultItemObjectPopup, action) => {
  switch (action.type) {
    case constants.OBJECT_MAP_ITEM_OBJECT_POPUP:
      return update(state, {
        $merge: action.payload
      });
    case constants.MAP_RESETS_ALL:
      return defaultItemObjectPopup;
    default:
      return state;
  }
};

const defaultMapChange = {
  mode: "NONE",
  polygon: []
};

/* Храним состояние карты */
const mapChange = (state = defaultMapChange, action) => {
  switch (action.type) {
    case constants.OBJECT_MAP_CHANGE_MAP:
      return update(state, {
        $merge: action.payload
      });
    case constants.MAP_RESETS_ALL:
      return defaultMapChange;
    default:
      return state;
  }
};

const defaultMapBounds = {
  northWest: [],
  southEast: [],
  centerMap: [55.019331, 82.939752],
  mapZoom: 13,
  isErrorTile: false
};

/* Храним положение карты */
const mapBounds = (state = defaultMapBounds, action) => {
  switch (action.type) {
    case constants.OBJECT_MAP_ADD_BOUNDS:
      return update(state, {
        $merge: action.payload
      });
    case constants.MAP_RESETS_ALL:
      return defaultMapBounds;
    default:
      return state;
  }
};

export default combineReducers({
  saveObjectsId,
  savePointsMap,
  addPoints,
  itemObjectPopup,
  mapChange,
  mapBounds
});
