// TODO CONSTANTS Страницы права

export default {
  // Получение свойств роли
  PROPERTY_ROLE_GET: 'PROPERTY_ROLE_GET',
  PROPERTY_ROLE_GET_SUCCESS: 'PROPERTY_ROLE_GET_SUCCESS',
  PROPERTY_ROLE_GET_ERROR: 'PROPERTY_ROLE_GET_ERROR',

  // Статус обновления поля
  PROPERTY_ROLE_UPDATE: 'PROPERTY_ROLE_UPDATE',
  PROPERTY_ROLE_UPDATE_SUCCESS: 'PROPERTY_ROLE_UPDATE_SUCCESS',
  PROPERTY_ROLE_UPDATE_ERROR: 'PROPERTY_ROLE_UPDATE_ERROR',

  // Сброс на дефолтные настройки
  PROPERTY_ROLE_DEFAULT: 'PROPERTY_ROLE_DEFAULT'
}