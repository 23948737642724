import { combineReducers } from "redux";
import constants from "../../../../constants";
import update from "immutability-helper";

const defaultObjectPropertyDataAutoComplete = {
  processSend: false
};

const objectPropertyDataAutoComplete = (state = defaultObjectPropertyDataAutoComplete, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_AUTO_COMPLETE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.OBJECT_PROPERTY_AUTO_COMPLETE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.OBJECT_PROPERTY_AUTO_COMPLETE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

const objectPropertySourceAutoComplete = (state = {}, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_AUTO_COMPLETE_SOURCE:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

const defaultObjectPropertySearchHouse = {
  city: null,
  street: null,
  number_house: null,
};

const objectPropertySearchHouse = (state = defaultObjectPropertySearchHouse, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_ADD_LOCATION:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.OBJECT_PROPERTY_RESETS:
      return defaultObjectPropertySearchHouse;
    default:
      return state;
  }
};

const loadObjectPropertyFilter = (state = {}, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_FILTER:
      return state;
    case constants.OBJECT_PROPERTY_FILTER_SUCCESS:
      if (!action.payload.hasOwnProperty("fields")) return state;
      return action.payload;
    case constants.OBJECT_PROPERTY_FILTER_FIXED:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });
    case constants.OBJECT_PROPERTY_FILTER_ERROR:
      return state;
    case constants.OBJECT_PROPERTY_RESETS:
      return {};
    default:
      return state;
  }
};

const loadObjectPropertyGeo = (state = {}, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_GEO:
      return state;
    case constants.OBJECT_PROPERTY_GEO_SUCCESS:
      return update(state, {
        $merge: action.payload
      });
    case constants.OBJECT_PROPERTY_GEO_ERROR:
      return state;
    case constants.OBJECT_PROPERTY_GEO_INSERT:
      return update(state, {
        $merge: action.payload
      });
    case constants.OBJECT_PROPERTY_RESETS:
      return {};
    default:
      return state;
  }
};

const objectPropertySendProperty = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_SEND:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.OBJECT_PROPERTY_SEND_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.OBJECT_PROPERTY_SEND_ERROR:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.OBJECT_PROPERTY_RESETS:
      return { processSend: false };
    default:
      return state;
  }
};

const objectPropertySendPropertySave = (state = {}, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_SAVE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const renderObjectPropertyFilter = (state = [], action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_FILTER_RENDER:
      return action.payload;
    case constants.OBJECT_PROPERTY_FILTER_RENDER_FIXED:
      return update(state, {
        [action.payload.lvl]: {
          [action.payload.itemPole]: {
            [action.payload.keyObject]: {
              $set: action.payload.array
            }
          }
        }
      });
    case constants.OBJECT_PROPERTY_FILTER_UPDATE_POLE:
      return update(state, {
        [action.payload.lvl]: {
          [action.payload.itemPole]: {
            $set: action.payload.pole
          }
        }
      });
    case constants.OBJECT_PROPERTY_RESETS:
      return [];
    default:
      return state;
  }
};

const objectPropertyFilterChange = (state = {}, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_FILTER_CHANGE:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });
    case constants.OBJECT_PROPERTY_FILTER_ADD_GROUP:
      return update(state, {
        $merge: action.payload
      });
    case constants.OBJECT_PROPERTY_RESETS:
      return {};
    default:
      return state;
  }
};

let defaultObjectPropertyFilterChangeMap = {
  coords: [],
  isMarker: false,
  blockedMap: false,
  locked: false
};

const objectPropertyFilterChangeMap = (state = defaultObjectPropertyFilterChangeMap, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_CHANGE_MAP:
      return update(state, {
        $merge: action.payload
      });
    case constants.OBJECT_PROPERTY_RESETS:
      return defaultObjectPropertyFilterChangeMap;
    default:
      return state;
  }
};

const objectPropertyFilterValidate = (state = {}, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_FILTER_VALIDATE:
      return update(state, {
        $merge: action.payload
      });
    case constants.OBJECT_PROPERTY_RESETS:
      return {};
    default:
      return state;
  }
};

const objectPropertyStaticFilters = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.OBJECT_PROPERTY_STATIC_FILTER:
      return { processSend: true };
    case constants.OBJECT_PROPERTY_STATIC_FILTER_SUCCESS:
      return update(state, {
        $set: Object.assign(action.payload, { processSend: false })
      });
    case constants.OBJECT_PROPERTY_STATIC_FILTER_ERROR:
      return { processSend: false };
    case constants.OBJECT_PROPERTY_RESETS:
      return { processSend: false };
    default:
      return state;
  }
};

export default combineReducers({
  objectPropertyDataAutoComplete,
  objectPropertySourceAutoComplete,
  objectPropertySearchHouse,
  loadObjectPropertyGeo,
  loadObjectPropertyFilter,
  objectPropertyFilterChange,
  objectPropertyFilterValidate,
  renderObjectPropertyFilter,
  objectPropertyFilterChangeMap,
  objectPropertyStaticFilters,
  objectPropertySendProperty,
  objectPropertySendPropertySave
});
