// TODO CONSTANTS Свойств роли (поля)

export default {
  // Инициализация компонента
  PROPERTY_ROLE_BOX_INIT: 'PROPERTY_ROLE_BOX_INIT',

  // Редактирование действий в разделе
  PROPERTY_ROLE_BOX_EDIT: 'PROPERTY_ROLE_BOX_EDIT',

  // Сброс на дефолтные данные
  PROPERTY_ROLE_BOX_DEFAULT: 'PROPERTY_ROLE_BOX_DEFAULT',
}