// TODO CONSTANTS Страница настройки экспорта

export default {
  // Хранение данных
  SOURCE_ITEM_SETTINGS: 'SOURCE_ITEM_SETTINGS',

  // Обновление колличества полей
  SOURCE_ITEM_SETTINGS_UPDATE_RENDER_POLE: 'SOURCE_ITEM_SETTINGS_UPDATE_RENDER_POLE',

  // Загружаем дефолтные значения полей и следим за новыми
  SOURCE_ITEM_SETTINGS_INIT_POLE: 'SOURCE_ITEM_SETTINGS_INIT_POLE',
  SOURCE_ITEM_SETTINGS_UPDATE_POLE: 'SOURCE_ITEM_SETTINGS_UPDATE_POLE',

  // Добавляем новые поля
  SOURCE_ITEM_SETTINGS_ADD_LINE: 'SOURCE_ITEM_SETTINGS_ADD_LINE',

  // Удаляем поля
  SOURCE_ITEM_SETTINGS_DELETE_LINE: 'SOURCE_ITEM_SETTINGS_DELETE_LINE',

  // Обновляем данные поля
  SOURCE_ITEM_SETTINGS_UPDATE_POLE_DATA: 'SOURCE_ITEM_SETTINGS_UPDATE_POLE_DATA',

  // Подгрузка геолокации
  SOURCE_ITEM_SETTINGS_GEO: 'SOURCE_ITEM_SETTINGS_GEO',
  SOURCE_ITEM_SETTINGS_GEO_SUCCESS: 'SOURCE_ITEM_SETTINGS_GEO_SUCCESS',
  SOURCE_ITEM_SETTINGS_GEO_ERROR: 'SOURCE_ITEM_SETTINGS_GEO_ERROR',

  // Взаимодействие с автокомплитом
  SOURCE_ITEM_SETTINGS_AUTOCOMPLETE: 'SOURCE_ITEM_SETTINGS_AUTOCOMPLETE',
  SOURCE_ITEM_SETTINGS_AUTOCOMPLETE_SUCCESS: 'SOURCE_ITEM_SETTINGS_AUTOCOMPLETE_SUCCESS',
  SOURCE_ITEM_SETTINGS_AUTOCOMPLETE_ERROR: 'SOURCE_ITEM_SETTINGS_AUTOCOMPLETE_ERROR',

  // Сохраняем автокомплит
  SOURCE_ITEM_SETTINGS_AUTOCOMPLETE_SAVE: 'SOURCE_ITEM_SETTINGS_AUTOCOMPLETE_SAVE',

  // Сброс параметров на дефолтные
  SOURCE_ITEM_SETTINGS_DEFAULT: 'SOURCE_ITEM_SETTINGS_DEFAULT',
}
