import { combineReducers } from 'redux';
import constants from "../../../../constants";
import update from "immutability-helper";

// TODO REDUCERS Страницы права

const defaultPropertyItemRole = {
  processSend: false
};

// Храним данные роли
const propertyItemRole = (state = defaultPropertyItemRole, action) => {
  switch (action.type) {
    case constants.PROPERTY_ROLE_GET:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.PROPERTY_ROLE_GET_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.PROPERTY_ROLE_GET_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

const defaultPropertyItemRoleUpdate = {
  processSend: false
};

// Обновление свойств роли
const propertyItemRoleUpdate = (state = defaultPropertyItemRoleUpdate, action) => {
  switch (action.type) {
    case constants.PROPERTY_ROLE_UPDATE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.PROPERTY_ROLE_UPDATE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.PROPERTY_ROLE_UPDATE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

export default combineReducers({
  propertyItemRole,
  propertyItemRoleUpdate
});