// TODO CONSTANTS Свойств роли (разделы)

export default {
  // Инициализация компонента
  PROPERTY_ROLE_SECTION_INIT: 'PROPERTY_ROLE_SECTION_INIT',

  // Редактирование действий в разделе
  PROPERTY_ROLE_SECTION_EDIT: 'PROPERTY_ROLE_SECTION_EDIT',

  // Сброс на дефолтные данные
  PROPERTY_ROLE_SECTION_DEFAULT: 'PROPERTY_ROLE_SECTION_DEFAULT',
}