import { combineReducers } from 'redux';
import constants from "../../../../constants";
import update from "immutability-helper";

const objectPhotoFiles = (state = [], action) => {
  switch (action.type) {
    case constants.OBJECT_PHOTO_FILE_UP_ALL:
      return action.payload;
    case constants.OBJECT_PHOTO_FILE_UPLOAD:
      return update(state, {
        $push: action.payload
      });
    case constants.OBJECT_PHOTO_FILE_CHANGE:
      return update(state, {
        [action.payload.index]: {
          [action.payload.name]: { $set: action.payload.value }
        }
      });
    case constants.OBJECT_PHOTO_FILE_ORDER:
      return action.payload;
    case constants.OBJECT_PHOTO_FILE_UPLOAD_DELETE:
      return update(state, {
        $splice: [[action.payload, 1]]
      });
    case constants.OBJECT_SOURCE_RESET_ALL:
      return [];
    default:
      return state;
  }
};

const defaultObjectPhotoModalChange = {
  modalImg: false,
  modalDesc: false,
  modalPub: false,
  modalVideo: false,
  youtubeCode: "",
  fileIndex: null,
  file: null,
};

const objectModalChange = (state = defaultObjectPhotoModalChange, action) => {
  switch (action.type) {
    case constants.OBJECT_FILE_MODAL_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    case constants.OBJECT_FILE_CHANGE:
      return update(state, {
        file: {
          [action.payload.name]: { $set: action.payload.value }
        }
      });
    case constants.OBJECT_FILE_CHANGE_PUBLISHED:
      return update(state, {
        file: {
          published: {
            [action.payload.name]: {
              $set: action.payload.value
            }
          }
        }
      });
    default:
      return state;
  }
};

const objectVideoAll = (state = [], action) => {
  switch (action.type) {
    case constants.OBJECT_VIDEO_SET:
      return update(state, {
        $set: action.payload
      });
    case constants.OBJECT_VIDEO_ADD:
      return update(state, {
        $push: action.payload
      });
    case constants.OBJECT_VIDEO_DELETE:
      return update(state, {
        $splice: [[action.payload, 1]]
      });
    case constants.OBJECT_SOURCE_RESET_ALL:
      return [];
    default:
      return state;
  }
};

const defaultObjectVideoModalChange = {
  modal: false,
  value: "",
  isNoValid: false,
  validActive: false
};

const objectVideoModalChange = (state = defaultObjectVideoModalChange, action) => {
  switch (action.type) {
    case constants.OBJECT_VIDEO_MODAL_TOGGLE:
      return update(state, {
        modal: { $set: action.payload }
      });
    case constants.OBJECT_VIDEO_MODAL_FORM_CHANGE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

export default combineReducers({
  objectPhotoFiles,
  objectModalChange,
  objectVideoModalChange,
  objectVideoAll,
});
