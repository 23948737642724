// TODO CONSTANTS Страниц справочника

export default {
  // Процесс загрузки данных
  HANDBOOK_DATA_TABLE: 'HANDBOOK_DATA_TABLE',
  HANDBOOK_DATA_TABLE_SUCCESS: 'HANDBOOK_DATA_TABLE_SUCCESS',
  HANDBOOK_DATA_TABLE_ERROR: 'HANDBOOK_DATA_TABLE_ERROR',

  // Статус загрузки данных для селекта
  HANDBOOK_DATA_SELECTS: 'HANDBOOK_DATA_SELECTS',
  HANDBOOK_DATA_SELECTS_SUCCESS: 'HANDBOOK_DATA_SELECTS_SUCCESS',
  HANDBOOK_DATA_SELECTS_ERROR: 'HANDBOOK_DATA_SELECTS_ERROR',

  // Сохранение сортировки в таблице
  HANDBOOK_SAVE_SORT_PROPERTY_IN_TABLE: 'HANDBOOK_SAVE_SORT_PROPERTY_IN_TABLE',

  // Изменение свойств таблицы
  HANDBOOK_CHANGE_PROPERTY_IN_TABLE: 'HANDBOOK_CHANGE_PROPERTY_IN_TABLE',

  // Следим за свойствами модального окна на добавление или редактирования словаря
  HANDBOOK_MODAL_EDIT_OR_ADD_TOGGLE: 'HANDBOOK_MODAL_EDIT_OR_ADD_TOGGLE',
  // Следим за полями модального окна на добавление или редактирования словаря
  HANDBOOK_MODAL_EDIT_OR_ADD_CHANGE: 'HANDBOOK_MODAL_EDIT_OR_ADD_CHANGE',
  // Валидируем поля в модальном окне на добавление или редактирования словаря
  HANDBOOK_MODAL_EDIT_OR_ADD_VALIDATE: 'HANDBOOK_MODAL_EDIT_OR_ADD_VALIDATE',
  // Сброс при закрытии модалки на добавление или редактирования словаря
  HANDBOOK_MODAL_EDIT_OR_ADD_DEFAULT: 'HANDBOOK_MODAL_EDIT_OR_ADD_DEFAULT',

  // Статус отправки словаря
  HANDBOOK_EDIT_OR_ADD_DATA: 'HANDBOOK_EDIT_OR_ADD_DATA',
  HANDBOOK_EDIT_OR_ADD_DATA_SUCCESS: 'HANDBOOK_EDIT_OR_ADD_DATA_SUCCESS',
  HANDBOOK_EDIT_OR_ADD_DATA_ERROR: 'HANDBOOK_EDIT_OR_ADD_DATA_ERROR',

  // Отслеживаем изменения в модальном окне на удаление
  HANDBOOK_CONFIRM_DELETE_TOGGLE: 'HANDBOOK_CONFIRM_DELETE_TOGGLE',

  // Статус удаления словаря
  HANDBOOK_DELETE: 'HANDBOOK_DELETE',
  HANDBOOK_DELETE_SUCCESS: 'HANDBOOK_DELETE_SUCCESS',
  HANDBOOK_DELETE_ERROR: 'HANDBOOK_DELETE_ERROR',

  // Сброс данных на дефолтные
  HANDBOOK_DEFAULT_DATA: 'HANDBOOK_DEFAULT_DATA'
}
