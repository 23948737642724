import { combineReducers } from "redux";
import constants from "../../../constants";
import update from "immutability-helper";
import map from "./map";
import tables from "./tables";

const defaultSearchObjectDataFilters = {
  processSend: false
};

/* Хранение исходных данных фильтров */
const searchObjectDataFilters = (state = defaultSearchObjectDataFilters, action) => {
  switch (action.type) {
    case constants.SEARCH_OBJECT_LOAD_ALL_FILTERS:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.SEARCH_OBJECT_LOAD_ALL_FILTERS_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.SEARCH_OBJECT_LOAD_ALL_FILTERS_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return defaultSearchObjectDataFilters;
    default:
      return state;
  }
};

const defaultDataTableObject = {
  processSend: false
};

/* Хранение исходных данных */
const dataTableObject = (state = defaultDataTableObject, action) => {

  let oldTotal = ""
  if(state?.objects?.total) {
    oldTotal = state?.objects?.total
  }

  let asd = action.payload

  if(action.payload?.objects?.total === null) {
    asd.objects.total = oldTotal
  }


  switch (action.type) {
    case constants.SEARCH_OBJECT_DATA:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.SEARCH_OBJECT_DATA_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.SEARCH_OBJECT_DATA_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return defaultDataTableObject;
    default:
      return state;
  }
};

const defaultPropertyTableObject = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  loading: true,
  itemTable: "",
  data: []
};

/* Хранение данных и сортировки для таблицы  */
const propertyTableObject = (state = defaultPropertyTableObject, action) => {
  switch (action.type) {
    case constants.SEARCH_OBJECT_DATA:
      return update(state, {
        loading: { $set: true }
      });
    case constants.SEARCH_OBJECT_CHANGE_PROPERTY_IN_TABLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return defaultPropertyTableObject;
    default:
      return state;
  }
};

const defaultTableSortingOnAllPages = {
  sortItem: "",
  columnSort: "",
};

/* Хранение сортировки для таблицы на странице "Всех объектов" */
const saveSortTableSearch = (state = defaultTableSortingOnAllPages, action) => {
  switch (action.type) {
    case constants.OBJECT_TABLE_SAVE_SORTING_ON_PAGE_SEARCH:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

/* Хранение сортировки для таблицы на странице "Моих объектов" */
const saveSortTableMy = (state = defaultTableSortingOnAllPages, action) => {
  switch (action.type) {
    case constants.OBJECT_TABLE_SAVE_SORTING_ON_PAGE_MY_OBJECT:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

/* Хранение сортировки для таблицы на странице "Свободных объектов" */
const saveSortTableFree = (state = defaultTableSortingOnAllPages, action) => {
  switch (action.type) {
    case constants.OBJECT_TABLE_SAVE_SORTING_ON_PAGE_FREE_OBJECT:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

/* Хранение сортировки для таблицы на странице "Частников" */
const saveSortTablePrivate = (state = defaultTableSortingOnAllPages, action) => {
  switch (action.type) {
    case constants.OBJECT_TABLE_SAVE_SORTING_ON_PAGE_PRIVATE_OBJECT:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

/* Хранение сортировки для таблицы на странице "Модерация" */
const saveSortTableModeration = (state = defaultTableSortingOnAllPages, action) => {
  switch (action.type) {
    case constants.OBJECT_TABLE_SAVE_SORTING_ON_PAGE_MODERATION_OBJECT:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

/* Хранение фильтров после парсинга */
const searchObjectParseFilters = (state = null, action) => {
  switch (action.type) {
    case constants.SEARCH_OBJECT_ADD_ALL:
      return action.payload;
    case constants.SEARCH_OBJECT_UPDATE_POLE:
      const { lvl, itemPole, pole } = action.payload;
      return update(state, {
        [lvl]: {
          [itemPole]: {
            $set: pole
          }
        }
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return null;
    default:
      return state;
  }
};

let defaultStateFilter = {
  openFilter: false,
  openSomeFilter: false,
  openAllFilter: false,
  openMap: false
};

/* Отслеживание состояния видимости фильтров */
const searchObjectChangeFilterToggle = (state = defaultStateFilter, action) => {
  switch (action.type) {
    case constants.OBJECT_FILTER_CHANGE_GROUP:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return defaultStateFilter;
    default:
      return state;
  }
};

const defaultSearchObjectChangeFilterValue = {
  isActive: false
};

/* Хранение выбранных значений в фильтре */
const searchObjectChangeFilterValue = (state = defaultSearchObjectChangeFilterValue, action) => {
  switch (action.type) {
    case constants.SEARCH_OBJECT_CHANGE_FILTER:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });
    case constants.SEARCH_OBJECT_CHANGE_SET_FILTER:
      return update(state, {
        $set: action.payload
      });
    case constants.SEARCH_OBJECT_CHANGE_FILTER_ALL:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_OBJECT_FILTER_RESET:
      return update(state, {
        $set: { ...action.payload }
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return defaultSearchObjectChangeFilterValue;
    default:
      return state;
  }
};

const searchObjectChangeFilters = (state= {isChangeFilters: true}, action) => {
  switch (action.type) {
    case constants.SEARCH_OBJECT_FILTER_CHANGE:
      return update(state, {
        $set: action.payload
      });
    default:
      return state;
  }
}

const searchObjectChangeFilterValueAction = (state = {}, action) => {
  switch (action.type) {
    case constants.SEARCH_OBJECT_UPDATE_FILTER_ACTION:
      return action.payload;
    case constants.SEARCH_OBJECT_CHANGE_FILTER_ALL:
      return action.payload;
    case constants.SEARCH_OBJECT_FILTER_RESET:
      return {};
    case constants.SEARCH_OBJECT_DEFAULT:
      return {};
    default:
      return state;
  }
};

/* Данные с автокомплита */
const searchObjectAutoCompleteSearch = (state = [], action) => {
  switch (action.type) {
    case constants.SEARCH_OBJECT_AUTOCOMPLETE:
      return state;
    case constants.SEARCH_OBJECT_AUTOCOMPLETE_SUCCESS:
      return update(state, {
        $set: action.payload
      });
    case constants.SEARCH_OBJECT_AUTOCOMPLETE_ERROR:
      return state;
    case constants.SEARCH_OBJECT_DEFAULT:
      return [];
    default:
      return state;
  }
};

/* Хранение и обновление новых списков для автокомплита */
const searchObjectAutoCompleteSearchParse = (state = {}, action) => {
  switch (action.type) {
    case constants.SEARCH_OBJECT_AUTOCOMPLETE_UPDATE:
      return update(state, {
        $merge: action.payload
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return {};
    default:
      return state;
  }
};

const defaultSearchObjectDataInformation = {
  processSend: false
};

/* Подгрузка данных для статичных полей */
const searchObjectDataInformation = (state = defaultSearchObjectDataInformation, action) => {
  switch (action.type) {
    case constants.SEARCH_OBJECT_LOAD_INFORMATION:
      return { processSend: true };
    case constants.SEARCH_OBJECT_LOAD_INFORMATION_SUCCESS:
      return update(state, {
        $set: Object.assign(action.payload, { processSend: false })
      });
    case constants.SEARCH_OBJECT_LOAD_INFORMATION_ERROR:
      return { processSend: false };
    case constants.SEARCH_OBJECT_DEFAULT:
      return defaultSearchObjectDataInformation;
    default:
      return state;
  }
};

const searchObjectToggleModal = (state = { modal: false }, action) => {
  switch (action.type) {
    case constants.OBJECT_SAVE_MODAL_TOGGLE:
      return update(state, {
        modal: { $set: action.payload }
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return { modal: false };
    default:
      return state;
  }
};

let defaultModalSaveFilter = {
  show_filter: null,
  filter: "",
  name_filter: "",
  // type_filter: "",
};

/* Данные модального окна на сохранение фильтров */
const changeModalSaveFilter = (state = defaultModalSaveFilter, action) => {
  switch (action.type) {
    case constants.OBJECT_SAVE_FILTER:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });
    case constants.OBJECT_SAVE_FILTER_RESETS:
      return defaultModalSaveFilter;
    default:
      return state;
  }
};

/* Отслеживание сохранения фильтра */
const searchObjectModalSaveFilter = (state = { processSend: false }, action) => {
  switch (action.type) {
    case constants.OBJECT_SAVE_FILTER_SEND:
      return { processSend: true };
    case constants.OBJECT_SAVE_FILTER_SEND_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: action.payload
      });
    case constants.OBJECT_SAVE_FILTER_SEND_ERROR:
      return { processSend: false };
    case constants.SEARCH_OBJECT_DEFAULT:
      return { processSend: false };
    default:
      return state;
  }
};

let defaultModalSaveFilterValid = {
  name_filter: false
};

/* Валидация модального окна при сохранении фильтров */
const changeModalSaveFilterErrors = (state = defaultModalSaveFilterValid, action) => {
  switch (action.type) {
    case constants.OBJECT_SAVE_FILTER_VALID:
      return update(state, {
        [action.payload.name]: { $set: action.payload.value }
      });
    case constants.SEARCH_OBJECT_DEFAULT:
      return defaultModalSaveFilterValid;
    default:
      return state;
  }
};

const defaultSearchObjectSaveMap = {
  mapBounds: {
    northWest: [],
    southEast: [],
    centerMap: [55.019331, 82.939752],
    mapZoom: 13,
    isErrorTile: false,
  },
  addPoints: {
    points: []
  },
  mapChange: {
    mode: "NONE",
    polygon: []
  }
};

const searchObjectSaveMap = (state = defaultSearchObjectSaveMap, action) => {
  switch (action.type) {
    case constants.SEARCH_OBJECT_SAVE_MAP:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultSavePageState = {
  isSave: false,
  activeFilterState: null,
  inActiveFilterState: null,
  columnState: null,
  mapState: null,
  showFilterState: null
};

// Сохранение параметров страницы
const savePageState = (state = defaultSavePageState, action) => {
  switch (action.type) {
    case constants.OBJECT_SAVE_PAGE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

export default combineReducers({
  dataTableObject,
  propertyTableObject,
  saveSortTableSearch,
  saveSortTableMy,
  saveSortTableFree,
  saveSortTablePrivate,
  saveSortTableModeration,
  searchObjectChangeFilters,
  searchObjectDataFilters,
  searchObjectParseFilters,
  searchObjectChangeFilterToggle,
  searchObjectChangeFilterValue,
  searchObjectChangeFilterValueAction,
  searchObjectAutoCompleteSearch,
  searchObjectAutoCompleteSearchParse,
  searchObjectDataInformation,
  searchObjectModalSaveFilter,
  searchObjectToggleModal,
  changeModalSaveFilter,
  changeModalSaveFilterErrors,
  searchObjectSaveMap,
  savePageState,
  map,
  tables,
});
