import constants from "../../constants";
import { combineReducers } from "redux";
import update from "immutability-helper";

// TODO REDUCERS Страницы офисов и сотрудников офиса

const defaultDataTablePropertyOffice = {
  processSend: true
};

// Хранение данных для таблиц с офисами или сотрудниками
const dataTablePropertyOffice = (state = defaultDataTablePropertyOffice, action) => {
  switch (action.type) {
    case constants.PROPERTY_OFFICE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.PROPERTY_OFFICE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.PROPERTY_OFFICE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.PROPERTY_OFFICE_DEFAULT:
      return defaultDataTablePropertyOffice;
    default:
      return state;
  }
};

const defaultSaveSortTableOffice = {
  sortItem: "",
  columnSort: "",
};

// Хранение сортировки для таблицы с офисами
const saveSortTableOffice = (state = defaultSaveSortTableOffice, action) => {
  switch (action.type) {
    case constants.PROPERTY_OFFICE_SAVE_SORT_TABLE_OFFICE:
      return action.payload;
    default:
      return state;
  }
};

const defaultSaveSortTableWorkers = {
  sortItem: "",
  columnSort: "",
};

// Хранение сортировки для таблицы с сотрудниками
const saveSortTableWorkers = (state = defaultSaveSortTableWorkers, action) => {
  switch (action.type) {
    case constants.PROPERTY_OFFICE_SAVE_SORT_TABLE_WORKERS:
      return action.payload;
    default:
      return state;
  }
};

const defaultPropertyTableOfficeOrWorkers = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  name_office: "",
  property_item: "",
  data: []
};

// Хранение свойств таблицы
const propertyTableOfficeOrWorkers = (state = defaultPropertyTableOfficeOrWorkers, action) => {
  switch (action.type) {
    case constants.PROPERTY_OFFICE_CHANGE_PROPERTY_IN_TABLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.PROPERTY_OFFICE_DEFAULT:
      return defaultPropertyTableOfficeOrWorkers;
    default:
      return state;
  }
};

const defaultPropertyModalEditRoleItemWorker = {
  modal: false,
  data: {},
  itemUser: null,
  dataRoles: []
};

// Редактирование роли у сотрудника офиса
const propertyModalEditRoleItemWorker = (state = defaultPropertyModalEditRoleItemWorker, action) => {
  switch (action.type) {
    case constants.PROPERTY_OFFICE_CHANGE_MODAL:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultPropertyModalEditRoleItemWorkerSend = {
  processSend: false
};

// Состояние отправки данных
const propertyModalEditRoleItemWorkerSend = (state = defaultPropertyModalEditRoleItemWorkerSend, action) => {
  switch (action.type) {
    case constants.PROPERTY_OFFICE_MODAL_EDIT_ITEM_WORKER:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.PROPERTY_OFFICE_MODAL_EDIT_ITEM_WORKER_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.PROPERTY_OFFICE_MODAL_EDIT_ITEM_WORKER_ERROR:
      return update(state, {
        processSend: false
      });
    default:
      return state;
  }
}

export default combineReducers({
  dataTablePropertyOffice,
  saveSortTableOffice,
  saveSortTableWorkers,
  propertyTableOfficeOrWorkers,
  propertyModalEditRoleItemWorker,
  propertyModalEditRoleItemWorkerSend,
});
