import constants from "../../constants";
import update from "immutability-helper";
import {combineReducers} from "redux";


const defaultPresentationObjectsData = {
  processSend: false,
  data: null
};

const presentationObjectsData = (state = defaultPresentationObjectsData, action) => {
  switch (action.type) {
    case constants.PRESENTATION_GET:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.PRESENTATION_GET_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { data: {...action.payload}}
      });
    case constants.PRESENTATION_GET_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.PRESENTATION_DEFAULT:
      return update(state, {
        processSend: { $set: false },
        data: { $set: null }
      });
    default:
      return state;
  }
};



const presentationRequestData = (state = null, action) => {
  switch (action.type) {
    case constants.PRESENTATION_REQUEST_DATA:
      return update(state, {
        $set: action.payload,
      });
    case constants.PRESENTATION_REQUEST_RESET:
      return update(state, {
        $set: null,
      });
    default:
      return state;
  }
};


const defaultPresentationHashData = {
  processSend: false,
  data: null,
  errors: null
};

const presentationHashData = (state = defaultPresentationHashData, action) => {
  switch (action.type) {
    case constants.PRESENTATION_CREATE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.PRESENTATION_CREATE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { data: {...action.payload}}
      });
    case constants.PRESENTATION_CREATE_ERROR:
      return update(state, {
        processSend: { $set: false },
        $merge: { errors: {...action.payload}}
      });
    default:
      return state;
  }
};

export default combineReducers({
  presentationObjectsData,
  presentationRequestData,
  presentationHashData
});
