import search from "./search";
import newObject from "./newObject";
import favouritesObjects from "./favouritesObjects";
import objectProperty from "./newObject/objectProperty";
import objectPhoto from "./newObject/objectPhoto";
import showObject from "./showObject";
import modalObject from "./modalObject";
import commercial from "./newObject/commercial";
import doubleObject from "./newObject/doubleObject";
import placementInAdvertising from "./newObject/placementInAdvertising";

export default {
  SEARCH_OBJECT_DEFAULT: 'SEARCH_OBJECT_DEFAULT',

  ...search,
  ...newObject,

  ...favouritesObjects,
  ...objectProperty,
  ...objectPhoto,
  ...showObject,
  ...modalObject,
  ...commercial,
  ...doubleObject,
  ...placementInAdvertising,
}
