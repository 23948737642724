import imports from "./imports";
import exports from "./exports";
import editSource from "./editSource";
import limit from "./exportlimit";
import exportSetting from "./exportSetting";
// import settings from "./settings";
import condition from "./exportSetting/editCondition";
import roles from "./roles";
import listValue from "./listValue";
import handbook from "./handbook";

export default {
  ...handbook,
  ...editSource,
  ...imports,
  ...exports,
  ...limit,
  ...exportSetting,
  // ...settings,
  ...condition,
  ...roles,
  ...listValue,
}
