import { combineReducers } from 'redux';
import constants from "../../constants";
import update from "immutability-helper";

// TODO REDUCERS Страницы клиентов

const defaultDataTableClients = {
  processSend: true
};

// Хранение данных для таблицы клиентов
const dataTableClients = (state = defaultDataTableClients, action) => {
  switch (action.type) {
    case constants.CLIENTS_DATA_TABLE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.CLIENTS_DATA_TABLE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.CLIENTS_DATA_TABLE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    case constants.CLIENTS_DEFAULT:
      return defaultDataTableClients;
    default:
      return state;
  }
};

const defaultSaveSortTableClients = {
  sortItem: "",
  columnSort: "",
};

// Хранение сортировки для таблицы клиентов
const saveSortTableClients = (state = defaultSaveSortTableClients, action) => {
  switch (action.type) {
    case constants.CLIENTS_SAVE_SORT_TABLE:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

let defaultPropertyTableClients = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  data: []
};

// Хранение свойств таблицы
const propertyTableClients = (state = defaultPropertyTableClients, action) => {
  switch (action.type) {
    case constants.CLIENTS_CHANGE_PROPERTY_IN_TABLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.CLIENTS_DEFAULT:
      return defaultPropertyTableClients;
    default:
      return state;
  }
};

export default combineReducers({
  dataTableClients,
  saveSortTableClients,
  propertyTableClients,
});
