// TODO CONSTANTS Страницы экспорта

export default {
  // Процесс загрузки данных
  EXPORTS_DATA_TABLE: 'EXPORTS_DATA_TABLE',
  EXPORTS_DATA_TABLE_SUCCESS: 'EXPORTS_DATA_TABLE_SUCCESS',
  EXPORTS_DATA_TABLE_ERROR: 'EXPORTS_DATA_TABLE_ERROR',

  // Изменение свойств таблицы
  EXPORTS_CHANGE_PROPERTY_IN_TABLE: 'EXPORTS_CHANGE_PROPERTY_IN_TABLE',

  // Отправка нового, редактирование текущего ресурса, удаление ресурса
  EXPORTS_DATA_EDIT_SOURCE: 'EXPORTS_DATA_EDIT_SOURCE',
  EXPORTS_DATA_EDIT_SOURCE_SUCCESS: 'EXPORTS_DATA_EDIT_SOURCE_SUCCESS',
  EXPORTS_DATA_EDIT_SOURCE_ERROR: 'EXPORTS_DATA_EDIT_SOURCE_ERROR',

  // Отслеживание изменений у модального окна "Удаление ресурса"
  EXPORTS_DATA_DELETE_CHANGE: 'EXPORTS_DATA_DELETE_CHANGE',

  // Сброс данных до изначальных
  EXPORTS_DEFAULT: 'EXPORTS_DEFAULT',
};