import newApplications from "./newApplications";
import propertyApplications from "./newApplications/propertyApplications";
import favouritesApplications from "./favouritesApplications";
import searchApplications from "./searchApplications";
import modalApplications from "./modalApplications";
import searchApplicationsInObject from "./searchApplicationsInObject";

export default {
  APPLICATIONS_DATA_RESETS: 'APPLICATIONS_DATA_RESETS',

  ...searchApplicationsInObject,
  ...modalApplications,
  ...newApplications,
  ...favouritesApplications,
  ...propertyApplications,
  ...searchApplications,
}
