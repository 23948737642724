import { combineReducers } from 'redux';
import constants from "../../../constants";
import update from "immutability-helper";

// TODO REDUCERS Страницы ролей

const defaultDataTableRoles = {
  processSend: true
};

// Загрузка данных
const dataTableRoles = (state = defaultDataTableRoles, action) => {
  switch (action.type) {
    case constants.ROLES_DATA:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.ROLES_DATA_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.ROLES_DATA_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

// Храним данные для полей (селекты)
const savePropertyPole = (state = {}, action) => {
  switch (action.type) {
    case constants.ROLES_SAVE_PROPERTY_POLES:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

const defaultSaveSortPropertyTableRoles = {
  sortItem: "",
  columnSort: "",
};

// Сохранение сортировки в таблице
const saveSortPropertyTableRoles = (state = defaultSaveSortPropertyTableRoles, action) => {
  switch (action.type) {
    case constants.ROLES_SAVE_SORT_PROPERTY_IN_TABLE:
      return update(state, {
        $merge: action.payload
      });
    default:
      return state;
  }
};

const defaultPropertyTableRoles = {
  searchTable: "",
  sortItem: "",
  columnSort: "",
  pagination: false,
  data: []
};

// Данные для таблицы
const propertyTableRoles = (state = defaultPropertyTableRoles, action) => {
  switch (action.type) {
    case constants.ROLES_CHANGE_PROPERTY_IN_TABLE:
      return update(state, {
        $merge: action.payload
      });
    case constants.ROLES_DEFAULT:
      return defaultPropertyTableRoles;
    default:
      return state;
  }
};

const defaultRolesEditToggleModal = {
  modal: false,
  data: null
};

// Хранение состояния модального окна редактирование/добавление роли
const rolesEditModalToggle = (state = defaultRolesEditToggleModal, action) => {
  switch (action.type) {
    case constants.ROLES_EDIT_TOGGLE_MODAL:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

// Храним изменения о роли
const rolesEditModalChange = (state = {}, action) => {
  switch (action.type) {
    case constants.ROLES_EDIT_TOGGLE_CHANGE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.ROLES_DEFAULT:
      return {};
    default:
      return state;
  }
};

// Храним состояние валидации в модальном окне редактирование/добавление роли
const rolesEditModalValid = (state = {}, action) => {
  switch (action.type) {
    case constants.ROLES_EDIT_FORM_VALIDATE:
      return update(state, {
        $merge: { ...action.payload }
      });
    case constants.ROLES_DEFAULT:
      return {};
    default:
      return state;
  }
};

const defaultEditOrAddRoleStatus = {
  processSend: false
};

// Отправка новой роли или редактирование старой статус
const editOrAddRoleStatus = (state = defaultEditOrAddRoleStatus, action) => {
  switch (action.type) {
    case constants.ROLES_ADD_OR_EDIT_ROLE:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.ROLES_ADD_OR_EDIT_ROLE_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.ROLES_ADD_OR_EDIT_ROLE_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

const defaultRolesToggleModal = {
  modal: false,
  data: null
};

// Храним состояние модального окна на удаление роли
const rolesConfirmDeleteToggleModal = (state = defaultRolesToggleModal, action) => {
  switch (action.type) {
    case constants.ROLES_CONFIRM_DELETE_TOGGLE_MODAL:
      return update(state, {
        $merge: { ...action.payload }
      });
    default:
      return state;
  }
};

const defaultRoleDropStatus = {
  processSend: false
};

// Храним статус удаления роли
const roleDropStatus = (state = defaultRoleDropStatus, action) => {
  switch (action.type) {
    case constants.ROLES_DROP_STATUS:
      return update(state, {
        processSend: { $set: true }
      });
    case constants.ROLES_DROP_STATUS_SUCCESS:
      return update(state, {
        processSend: { $set: false },
        $merge: { ...action.payload }
      });
    case constants.ROLES_DROP_STATUS_ERROR:
      return update(state, {
        processSend: { $set: false }
      });
    default:
      return state;
  }
};

export default combineReducers({
  dataTableRoles,
  savePropertyPole,
  propertyTableRoles,
  saveSortPropertyTableRoles,
  rolesEditModalToggle,
  rolesEditModalChange,
  rolesEditModalValid,
  editOrAddRoleStatus,
  rolesConfirmDeleteToggleModal,
  roleDropStatus,
});
